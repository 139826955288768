import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = usersServiceAdmin => {
    const data = usersServiceAdmin.map(userServiceAdmin => ({
        id: userServiceAdmin.id,
        empresa: userServiceAdmin.service_name,
        nome: userServiceAdmin.profile.name,
        email: userServiceAdmin.email,
        documento: userServiceAdmin.profile.document,
        telefone: userServiceAdmin.profile.phone,
        cargo: userServiceAdmin.profile.responsibility,
        criado_em: userServiceAdmin.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'empresa', 'nome', 'email', 'documento', 'telefone', 'cargo', 'criado_em']
    });
    downloadCSV(csv, 'usersServiceAdmin');
}

export default exporter;
