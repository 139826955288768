import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = curiosities => {
    const data = curiosities.map(curiosity => ({
        id: curiosity.id,
        tema: curiosity.theme_name,
        cidade: curiosity.city_name,
        titulo_pt_br: curiosity.title.pt_br,
        titulo_en_us: curiosity.title.en_us,
        descricao_pt_br: curiosity.description.pt_br,
        descricao_en_us: curiosity.description.en_us,
        criado_em: curiosity.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'tema', 'cidade', 'titulo_pt_br', 'titulo_en_us',
                 'descricao_pt_br', 'descricao_en_us', 'criado_em']
    });
    downloadCSV(csv, 'curiosities');
}

export default exporter;
