import React from 'react';

const styles = {
    main: {
        display: 'flex',
        flexWrap: 'wrap',
    },
};

const NewValueServiceUpdateField = ({ record }) => {

    if (record.field_service_update === 10)

        return (
          <span style={styles.main}>
          {
            <a
              href={record.new_value}
              target="_blank"
              rel="noopener noreferrer"
              onClick={ (e) => { e.stopPropagation() }}
            >Ver</a>
          }
          </span>
        );
    else
        return <span>
                 {record.new_value}
               </span>;
};

NewValueServiceUpdateField.defaultProps = {
    addLabel: true,
    source: 'Link'
};

export default NewValueServiceUpdateField;
