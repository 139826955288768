import React from 'react';
import {
  required,
  Create,
  SimpleForm,
  FileInput,
  FileField
} from 'react-admin';

const validateFile = [required('Selecione um arquivo!')];

const TermsOfUseCreate = (props) => (
  <Create title="Novo Termos de Uso" {...props}>
    <SimpleForm redirect="/terms-of-use">
      <FileInput source="file" label="Tamanho Máximo do Arquivo: 5MB" maxSize={5000000} accept="application/*,video/*,audio/*,image/*"  validate={validateFile} >
        <FileField src="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);

export default TermsOfUseCreate;
