import React from 'react';
import {
    Show,
    TextField,
    TabbedShowLayout,
    Tab,
    SimpleShowLayout
} from 'react-admin';

// own ui
import CustomImageField from '../../ui/custom-image-field';

const CustomerShow = props => (
    <Show
      title="Usuário" {...props}>
      <TabbedShowLayout>
          <Tab label="Dados do Usuário">
            <SimpleShowLayout>
                <TextField source="id" />
                <CustomImageField source="image_thumb" label="Imagem Thumb" sortable={false}/>
                <TextField source="email" label="E-mail" />
                <TextField source="profile.name" label="Nome" sortable={false} />
                <TextField source="profile.gender.name" label="Gênero" sortable={false} />
                <TextField source="profile.birthdate" label="Data de Nascimento" sortable={false} />
                <TextField source="profile.phone" label="Telefone" sortable={false} />
                <TextField source="profile.type_locomotion.name" label="Locomoção" sortable={false} />
            </SimpleShowLayout>
          </Tab>
          <Tab label="Endereço">
            <SimpleShowLayout>
                <TextField source="address.city.name" label="Cidade" sortable={false} />
                <TextField source="address.zip_code" label="CEP" sortable={false} />
                <TextField source="address.street" label="Rua" sortable={false} />
                <TextField source="address.number" label="Número" sortable={false} />
                <TextField source="address.complement" label="Complemento" sortable={false} />
                <TextField source="address.neighborhood" label="Bairro" sortable={false} />
            </SimpleShowLayout>
          </Tab>
          <Tab label="Passaporte">
            <SimpleShowLayout>
                <TextField source="passport.number" label="Número" />
            </SimpleShowLayout>
          </Tab>
          <Tab label="Campanha">
            <SimpleShowLayout>
                <TextField source="campaign.name" label="Nome" sortable={false} />
                <TextField source="campaign.code" label="Código" sortable={false} />
            </SimpleShowLayout>
          </Tab>
      </TabbedShowLayout>
    </Show>
);

export default CustomerShow;
