import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = copartners => {
    const data = copartners.map(copartner => ({
        id: copartner.id,
        nome: copartner.name,
        site: copartner.site,
        criado_em: copartner.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'nome', 'site', 'criado_em']
    });
    downloadCSV(csv, 'copartners');
}

export default exporter;
