import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton
} from 'react-admin';

// own ui
import CustomFilterTextServiceStatusActive from '../../ui/custom-filter-text-status-active';
import ServiceAdminExporter from '../../exporter/service-admin-exporter';
import ButtonServiceStatus from '../../ui/button-service-admin-status';
import ButtonServiceShow from '../../ui/button-service-admin-show';
import rowServiceStyle from '../../ui/row-service-admin-style';

const ServiceAdminList = (props) => (
    <List
      title="Admins de Serviço" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterTextServiceStatusActive /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ ServiceAdminExporter } >
        <Datagrid
          rowStyle={rowServiceStyle()}>
          <TextField source="id" sortable={true} />
          <TextField source="service_name" label="Serviço" sortable={false} />
          <TextField source="profile.name" label="Nome" sortable={false} />
          <TextField source="email" label="E-mail" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
          <ButtonServiceStatus label="Status" />
          <ButtonServiceShow label="Ver" />
          <EditButton />
        </Datagrid>
    </List>
);

export default ServiceAdminList;
