import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = customers => {
    const data = customers.map(customer => ({
        id: customer.id,
        passaporte: customer.passport.number,
        email: customer.email,
        documento: customer.document,
        nome: customer.profile.name,
        genero: customer.profile.gender ? customer.profile.gender.name : '',
        data_nascimento: customer.profile.birthdate,
        telefone: customer.profile.phone,
        tipo_locomocao: customer.profile.type_locomotion ? customer.profile.type_locomotion.name : '',
        cidade: customer.address.city ? customer.address.city.name : '',
        cep: customer.address.zip_code,
        rua: customer.address.street,
        numero: customer.address.number,
        complemento: customer.address.complement,
        bairro: customer.address.neighborhood,
        criado_em: customer.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'passaporte', 'email', 'documento', 'nome', 'genero',
                 'data_nascimento', 'telefone', 'tipo_locomocao', 'cidade',
                 'cep', 'rua', 'numero', 'complemento', 'bairro', 'criado_em']
    });
    downloadCSV(csv, 'users');
}

export default exporter;
