import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    BooleanField
} from 'react-admin';

// own ui
import CustomFilterText from '../../ui/custom-filter-text';
import LinkField from '../../ui/link-field';
import PrivacyPolicyExporter from '../../exporter/privacy-policy-exporter';

const PrivacyPolicyList = (props) => (
    <List
      title="Política de Privacidade" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterText /> }
      bulkActionButtons={ false }
      exporter={ PrivacyPolicyExporter } >
        <Datagrid>
          <TextField source="id" sortable={true} />
          <TextField source="version" label="Versão" sortable={false} />
          <TextField source="resource_type" label="Tipo" sortable={false} />
          <LinkField source="url" text="Ver" label="Arquivo" sortable={false}/>
          <BooleanField source="active" label="Ativo" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
        </Datagrid>
    </List>
);

export default PrivacyPolicyList;
