import React from 'react';
import {
  Show,
  TextField,
  BooleanField,
  SimpleShowLayout
} from 'react-admin';

const CampaignShow = props => (
    <Show
      title="Campanha" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" label="Nome" sortable={false} />
            <TextField source="code" label="Código" sortable={false} />
            <TextField source="count_used" label="Quantidade Utilizada" sortable={false} />
            <BooleanField source="active" label="Ativo" sortable={false} />
        </SimpleShowLayout>
    </Show>
);

export default CampaignShow;
