import React from 'react';
import {
    Show,
    TextField,
    BooleanField,
    SimpleShowLayout
} from 'react-admin';

const WayRouteShow = props => (
    <Show
      title="Rota" {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="way_name" label="Caminho" sortable={false} />
        <TextField source="city_departure.name" label="Cidade de Partida" sortable={false} />
        <TextField source="city_arrival.name" label="Cidade de Chegada" sortable={false} />
        <TextField source="description.pt_br" label="Descrição PT-BR" sortable={false} />
        <TextField source="description.en_us" label="Descrição EN-US" sortable={false} />
        <TextField source="physical_difficulty" label="Dificuldade Física" sortable={false} />
        <TextField source="technical_difficulty" label="Dificuldade Técnica" sortable={false} />
        <TextField source="total_distance" label="Distância Total" sortable={false} />
        <BooleanField source="shadow_presence" label="Tem Sombra?" sortable={false} />
        <TextField source="descent_distance" label="Distância de Descida" sortable={false} />
        <TextField source="ascent_distance" label="Distância de Subida" sortable={false} />
        <TextField source="ascent_medium_percentage" label="Porcentagem de Subida" sortable={false} />
        <BooleanField source="reverse" label="Inverso" sortable={false} />
        <BooleanField source="active" label="Ativo" sortable={false} />
      </SimpleShowLayout>
    </Show>
);

export default WayRouteShow;
