import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = stampPointsFiles => {
    const data = stampPointsFiles.map(stampPointsFile => ({
        id: stampPointsFile.id,
        versao: stampPointsFile.version,
        tipo: stampPointsFile.resource_type,
        url: stampPointsFile.secure_url,
        criado_em: stampPointsFile.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'versao', 'tipo', 'url', 'criado_em']
    });
    downloadCSV(csv, 'stampPointsFiles');
}

export default exporter;
