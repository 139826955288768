import React from 'react';
import { required, SelectInput, TextInput } from 'react-admin';

const validateStatus = [required('Selecione um status!')];

const ServiceUpdateStatus = ({ record }) => {

    if (record.status_service_update === 0)
        return <div>
                  <SelectInput source="status_service_update" label="Status" fullWidth={true}  choices={[
                      { id: 1, name: 'Aprovado' },
                      { id: 2, name: 'Reprovado' }
                  ]} validate={validateStatus} />
                  <TextInput source="message" label="Mensagem" fullWidth={true} />
               </div>
    else
        return <div>
                <TextInput source="status_service_update_name" label="Status" fullWidth={true} disabled />
                <TextInput source="message" label="Mensagem" fullWidth={true} disabled />
               </div>
};

export default ServiceUpdateStatus;
