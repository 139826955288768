import React from 'react';
import Chip from '@material-ui/core/Chip';

const styles = {
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 10
  },
  chip: { margin: 4 },
};

const EventDaysField = ({ record }) => {
    return (
      <span style={styles.main}>
        {record.days &&
            record.days.map(day => (
                <Chip
                    key={day.id}
                    style={styles.chip}
                    label={day.date_completed}
                />
            ))}
      </span>
    );
};

EventDaysField.defaultProps = {
  addLabel: true,
  source: 'Dias'
};

export default EventDaysField;
