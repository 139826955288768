var env = process.env.REACT_APP_NODE_ENV || 'development';

var config =
{
  development:
  {
    api:
    {
      api_key: "cfc56e4a8faeb1758adc75d99ca08d7f",
      url: "https://api-dev.institutoestradareal.com.br"
    },
    local_storage:
    {
      user: "user_admin_estrada_real",
      auth: "auth_admin_estrada_real"
    },
    jwt:
    {
      key: "7ab5c4c8d2096e887fadcb96f3601b60"
    },
    roles:
    {
      admin_users_admin: "6e3e4a5f40273c4ff1c36399f8646e50",
      admin_users_customer: "d2b10f05aa5d4449cdde1bb985243905",
      admin_images: "be851f0a1b2dafdb1e4f873849758d89",
      admin_contacts: "3acb1e3219ccdab98134addf74faeee8",
      admin_enterprise_contacts: "b3f422ae3d45235bef2a23d9ba8b8abd",
      admin_newsletters: "f710c9c445ce4da80c3c2860a0231d16",
      admin_licensed_products: "aa7b6acad2843baa30043bf79355d810",
      admin_tour_packages: "b68062c69e6e7461f7ddd7107ab428ff",
      admin_copartners: "d285abffd590b15e6d9a83edb9f7c2e9",
      admin_highlights: "543c2da92d4a28c0fb48ac99f90ad14e",
      admin_themes: "aae5bd2aae47df31922a244dca738fb1",
      admin_cities: "3d917b32702187b302e54743f4c37ffa",
      admin_ways: "8f218e5116f15886fcb9770f215ce2af",
      admin_way_routes: "5a4cc526ed522b50186cdfabc8de6afd",
      admin_check_points: "0680c0ab75250d2ab92453fa2d3bedce",
      admin_categories_terroirs: "61fc5583a078087e81aa781b387e268e",
      admin_attractives: "627ccdeeddfe107ef79e65c50a326d6f",
      admin_services: "94f1b135eeaea2166ba1a264abccbb3f",
      admin_terms_of_use: "c59f25aa72a1092f90f0ef24a5aeb2d2",
      admin_privacy_policys: "11426823a784c256588ce07f35976fb0",
      admin_reported_problems: "f058cd8470fb65c0b191d5d757859dd2",
      admin_stamp_points_files: "f929d4a5b9fc8676de8ac240cc9b5b51",
      admin_users_service_admin: "770949469ea7107cc84136e33d7def72",
      admin_updates_service: "198a16587aa04eb20dcfde739d1d416f",
      admin_pop_ups: "1d2d3f82ba5adefebcf11a5b6adc7d59",
      admin_events: "889c3bb2fa429f53ae361c8de0745d0a",
      admin_events_suggestion: "0991e43f235709d08b7fab5b3e5a38ae",
      admin_campaigns: "40affbe7599fc5715b3c5daecdc24d3e",
      admin_contests: "080aa54191ebae2bb690564ba9a40566"
    }
  },
  production:
  {
    api:
    {
      api_key: "cfc56e4a8faeb1758adc75d99ca08d7f",
      url: "https://api.institutoestradareal.com.br"
    },
    local_storage:
    {
      user: "user_admin_estrada_real",
      auth: "auth_admin_estrada_real"
    },
    jwt:
    {
      key: "7ab5c4c8d2096e887fadcb96f3601b60"
    },
    roles:
    {
      admin_users_admin: "6e3e4a5f40273c4ff1c36399f8646e50",
      admin_users_customer: "d2b10f05aa5d4449cdde1bb985243905",
      admin_images: "be851f0a1b2dafdb1e4f873849758d89",
      admin_contacts: "3acb1e3219ccdab98134addf74faeee8",
      admin_enterprise_contacts: "b3f422ae3d45235bef2a23d9ba8b8abd",
      admin_newsletters: "f710c9c445ce4da80c3c2860a0231d16",
      admin_licensed_products: "aa7b6acad2843baa30043bf79355d810",
      admin_tour_packages: "b68062c69e6e7461f7ddd7107ab428ff",
      admin_copartners: "d285abffd590b15e6d9a83edb9f7c2e9",
      admin_highlights: "543c2da92d4a28c0fb48ac99f90ad14e",
      admin_themes: "aae5bd2aae47df31922a244dca738fb1",
      admin_cities: "3d917b32702187b302e54743f4c37ffa",
      admin_ways: "8f218e5116f15886fcb9770f215ce2af",
      admin_way_routes: "5a4cc526ed522b50186cdfabc8de6afd",
      admin_check_points: "0680c0ab75250d2ab92453fa2d3bedce",
      admin_categories_terroirs: "61fc5583a078087e81aa781b387e268e",
      admin_attractives: "627ccdeeddfe107ef79e65c50a326d6f",
      admin_services: "94f1b135eeaea2166ba1a264abccbb3f",
      admin_terms_of_use: "c59f25aa72a1092f90f0ef24a5aeb2d2",
      admin_privacy_policys: "11426823a784c256588ce07f35976fb0",
      admin_reported_problems: "f058cd8470fb65c0b191d5d757859dd2",
      admin_stamp_points_files: "f929d4a5b9fc8676de8ac240cc9b5b51",
      admin_users_service_admin: "770949469ea7107cc84136e33d7def72",
      admin_updates_service: "198a16587aa04eb20dcfde739d1d416f",
      admin_pop_ups: "1d2d3f82ba5adefebcf11a5b6adc7d59",
      admin_events: "889c3bb2fa429f53ae361c8de0745d0a",
      admin_events_suggestion: "0991e43f235709d08b7fab5b3e5a38ae",
      admin_campaigns: "40affbe7599fc5715b3c5daecdc24d3e",
      admin_contests: "080aa54191ebae2bb690564ba9a40566"
    }
  }
};

module.exports = config[env];
