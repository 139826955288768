import React from 'react';
import {
    Show,
    TextField,
    BooleanField,
    SimpleShowLayout
} from 'react-admin';

// own ui
import CustomImageField from '../../ui/custom-image-field';

const LicensedProductShow = props => (
    <Show
      title="Produto Licenciado" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <CustomImageField source="image" label="Imagem" showAll={true} sortable={false}/>
            <TextField source="producer" label="Empresa" sortable={false} />
            <TextField source="product_name" label="Produto" sortable={false} />
            <TextField source="email" label="E-mail" sortable={false} />
            <TextField source="phone" label="Telefone" sortable={false} />
            <TextField source="site" label="Site" sortable={false} />
            <BooleanField source="active" label="Ativo" sortable={false} />
        </SimpleShowLayout>
    </Show>
);

export default LicensedProductShow;
