import React from 'react';
import {
    Show,
    TextField,
    BooleanField,
    SimpleShowLayout
} from 'react-admin';

// own ui
import CustomImageField from '../../ui/custom-image-field';
import { ColorField } from 'react-admin-color-input';

const WayShow = props => (
    <Show
      title="Destaque" {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <CustomImageField source="image_thumb" label="Imagem" showAll={true} sortable={false}/>
        <CustomImageField source="image_cover" label="Capa" showAll={true} sortable={false}/>
        <CustomImageField source="image_short_detail" label="Detalhe" showAll={true} sortable={false}/>
        <CustomImageField source="images" label="Galeria" showAll={true} sortable={false}/>
        <TextField source="city_departure.name" label="Cidade de Partida" sortable={false} />
        <TextField source="city_arrival.name" label="Cidade de Chegada" sortable={false} />
        <TextField source="name.pt_br" label="Nome PT-BR" sortable={false} />
        <TextField source="name.en_us" label="Nome EN-US" sortable={false} />
        <TextField source="short_description.pt_br" label="Breve Descrição PT-BR" sortable={false} />
        <TextField source="short_description.en_us" label="Breve Descrição EN-US" sortable={false} />
        <TextField source="description.pt_br" label="Descrição PT-BR" sortable={false} />
        <TextField source="description.en_us" label="Descrição EN-US" sortable={false} />
        <TextField source="short_text.pt_br" label="Breve Detalhe PT-BR" sortable={false} />
        <TextField source="short_text.en_us" label="Breve Detalhe EN-US" sortable={false} />
        <TextField source="total_distance" label="Distância" sortable={false} />
        <TextField source="ascent_descent_distance" label="Distância Descida Subida" sortable={false} />
        <TextField source="bike_travel_days" label="Dias de Bicicleta" sortable={false} />
        <TextField source="walking_travel_days" label="Dias a pé" sortable={false} />
        <TextField source="car_travel_days" label="Dias de Carro" sortable={false} />
        <TextField source="horse_travel_days" label="Dias de Cavalo" sortable={false} />
        <TextField source="motorcycle_travel_days" label="Dias de Moto" sortable={false} />
        <TextField source="asphalt_percentage" label="Porcentagem de Asfalto" sortable={false} />
        <TextField source="trail_percentage" label="Porcentagem de Trilha" sortable={false} />
        <TextField source="off_road_percentage" label="Porcentagem de Terra" sortable={false} />
        <TextField source="asphalt_distance" label="Distância de Asfalto" sortable={false} />
        <TextField source="trail_distance" label="Distância de Trilha" sortable={false} />
        <TextField source="off_road_distance" label="Distância de Terra" sortable={false} />
        <TextField source="tip_travel.pt_br" label="Dica PT" sortable={false} />
        <TextField source="tip_travel.en_us" label="Dica EN" sortable={false} />
        <ColorField source="color_base" label="Cor Base" sortable={false}/>
        <ColorField source="secondary_color" label="Cor Secundária" sortable={false}/>
        <BooleanField source="active" label="Ativo" sortable={false} />
      </SimpleShowLayout>
    </Show>
);

export default WayShow;
