import React from 'react';
import {
  required,
  Edit,
  TabbedForm,
  FormTab,
  TranslatableInputs,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  BooleanInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ArrayInput,
  SimpleFormIterator
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';
import CustomImageField from '../../ui/custom-image-field';
import RichTextInput from '../../ui/rich-text-input';
import { DateInput, TimeInput } from '../../ui/date-pickers';

export const styles =
{
  inlineWidth: { width: '20em' },
  inlineFormGroup: { display: 'inline-block', marginRight: 20 }
};

const useStyles = makeStyles(styles);
const validateName = [required('Digite o nome!')];
const validateDescription = [required('Digite a descrição!')];
const validateCity = [required('Selecione uma cidade!')];
const validateDays = [required('Cadastre pelo menos um dia!')];
const validateDate = [required('Digite a data!')];
const validateImage = [required('Selecione pelo menos uma imagem!')];

const EventEdit = (props) =>
{
  const classes = useStyles();

  return (
  <Edit {...props} title={ <EditTitle subtitle="Editar Evento: " value="name" subvalue="pt_br" /> }>
    <TabbedForm redirect="/event" toolbar={<CustomEditToolbar />} >
      <FormTab label="Dados">
        <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
          <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
          <RichTextInput source="description" label="Descrição" validate={validateDescription} />
          <TextInput source="more_details" label="Mais Detalhes" fullWidth={true} />
        </TranslatableInputs>
        <br />
        <BooleanInput source="is_free" label="Gratuito?" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} />
        <BooleanInput source="active" label="Ativo" />
      </FormTab>
      <FormTab label="Endereço">
        <ReferenceInput label="Cidade" source="address.city_id" fullWidth={true} reference="setup/cities/with/detail" validate={validateCity}>
          <AutocompleteInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
        </ReferenceInput>
        <TextInput source="address.street" label="Rua" fullWidth={true} />
        <TextInput source="address.number" label="Número" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} fullWidth={true} />
        <TextInput source="address.zip_code" label="CEP" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} fullWidth={true}  />
        <TextInput source="address.complement" label="Complemento" fullWidth={true} />
        <TextInput source="address.neighborhood" label="Bairro" fullWidth={true} />
        <NumberInput source="address.lat" label="Latitude" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} />
        <NumberInput source="address.lng" label="Longitude" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} />
      </FormTab>
      <FormTab label="Dias e Imagens">
        <ArrayInput label="Dias" source="days" validate={validateDays}>
          <SimpleFormIterator disableReordering>
            <DateInput source="date" label="Data" options={{ format: 'dd/MM/yyyy', clearable: false }} isRequired validate={validateDate} />
            <TimeInput source="hour_init" label="Hora de Início" options={{ format: 'HH:mm', ampm: false, clearable: true }} />
            <TimeInput source="hour_end" label="Hora de Término" options={{ format: 'HH:mm', ampm: false, clearable: true }} />
          </SimpleFormIterator>
        </ArrayInput>
        <CustomImageField source="images" label="Imagens" showAll={true} sortable={false}/>
        <br/>
        <ReferenceArrayInput label="Imagens" perPage={5000} source="images_id" fullWidth={true} reference="image" validate={validateImage}>
          <AutocompleteArrayInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
        </ReferenceArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
)};

export default EventEdit;
