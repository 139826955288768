import React from 'react';
import {
  required,
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin';

const validateProducer = [required('Digite o produtor!')];
const validateProductName = [required('Digite o produto!')];
const validateEmail = [required('Digite o e-mail!')];
const validatePhone = [required('Digite o telefone!')];
const validateSite = [required('Digite o site!')];
const validateImage = [required('Selecione uma imagem!')];

const LicensedProductCreate = (props) => (
  <Create title="Novo Produto Licenciado" {...props}>
    <SimpleForm redirect="/licensed-product" >
      <TextInput source="producer" label="Empresa" fullWidth={true} validate={validateProducer} />
      <TextInput source="product_name" label="Produto" fullWidth={true} validate={validateProductName} />
      <TextInput source="email" label="E-mail"  type="email" fullWidth={true} validate={validateEmail} />
      <TextInput source="phone" label="Telefone" fullWidth={true} validate={validatePhone} />
      <TextInput source="site" label="Site" fullWidth={true} validate={validateSite} />
      <ReferenceInput label="Imagem" source="image_id" fullWidth={true} reference="image" validate={validateImage}>
        <AutocompleteInput optionText="name" optionValue="id" shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default LicensedProductCreate;
