import React from 'react';
import Chip from '@material-ui/core/Chip';

const styles = {
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 10
  },
  chip: { margin: 4 }
};

const LinksField = ({ record }) => {
  return (
    <span style={styles.main}>
      {record.links &&
        record.links.map(link => (
          <Chip
            key={link.id}
            style={styles.chip}
            label={link.url}
          />
        ))}
    </span>
  );
};

LinksField.defaultProps = {
  addLabel: true,
  source: 'Links'
};

export default LinksField;
