import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    DateField,
    EditButton
} from 'react-admin';

// own ui
import CustomFilterTextActive from '../../ui/custom-filter-text-active';
import CustomImageField from '../../ui/custom-image-field';
import ContestExporter from '../../exporter/contest-exporter';

const ContestList = (props) => (
    <List
      title="Concursos" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterTextActive /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ ContestExporter } >
        <Datagrid
          rowClick="show">
          <TextField source="id" sortable={true} />
          <CustomImageField source="image_cover" label="Capa" sortable={false}/>
          <TextField source="name.pt_br" label="Nome" sortable={false} />
          <TextField source="count_participants" label="Participantes" sortable={false} />
          <BooleanField source="active" label="Ativo" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
          <EditButton />
        </Datagrid>
    </List>
);

export default ContestList;
