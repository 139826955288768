import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import config from '../config/config';

var jwt = require('jwt-simple');
const apiUrl = config.api.url;
const apikey = config.api.api_key;
const jwtKey = config.jwt.key;
const localStorageUser = config.local_storage.user;
const localStorageAuth = config.local_storage.auth;

const authProvider = (type, params) =>
{
  if (type === AUTH_LOGIN)
  {
    const { email, password } = params;

    const request = new Request(apiUrl + '/admin/sign',
    {
      method: 'POST',
      body: JSON.stringify({ email: email, password: password}),
      headers: new Headers({ 'Content-Type': 'application/json', 'x-api-key': apikey }),
    })

    return fetch(request).then(response =>
    {
      if (response.status === 400)
        return Promise.reject();

      return response.json();
    })
    .then(data =>
    {
      localStorage.setItem(jwt.encode(localStorageUser, jwtKey), jwt.encode(JSON.stringify(data.result.user), jwtKey));
      localStorage.setItem(jwt.encode(localStorageAuth, jwtKey), jwt.encode(JSON.stringify(data.result.auth), jwtKey));

      return Promise.resolve();
    });
  }
  else if (type === AUTH_LOGOUT)
  {
    localStorage.removeItem(jwt.encode(localStorageUser, jwtKey));
    localStorage.removeItem(jwt.encode(localStorageAuth, jwtKey));

    return Promise.resolve();
  }
  else if (type === AUTH_ERROR)
  {
    if (params.status === 401 || params.status === 403)
    {
      localStorage.removeItem(jwt.encode(localStorageUser, jwtKey));
      localStorage.removeItem(jwt.encode(localStorageAuth, jwtKey));

      return Promise.reject();
    }

    return Promise.resolve();
  }
  else if (type === AUTH_CHECK)
  {
    var item = localStorage.getItem(jwt.encode(localStorageUser, jwtKey));
    if (item !== null)
      return jwt.decode(item, jwtKey) ? Promise.resolve() : Promise.reject();
    else
      Promise.reject();
  }
  else if (type === AUTH_GET_PERMISSIONS)
  {
    const userkey = localStorage.getItem(jwt.encode(localStorageUser, jwtKey));
    if (userkey !== null && userkey !== undefined)
    {
      const roles = JSON.parse(jwt.decode(userkey, jwtKey)).roles;
      return roles ? Promise.resolve(roles) : Promise.reject();
    }
    else
      Promise.reject();
  }

  return Promise.reject('Method not found!');
};

export default authProvider;
