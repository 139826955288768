import React from 'react';
import {
    Show,
    TextField,
    SimpleShowLayout
} from 'react-admin';

const ServiceAdminShow = props => (
    <Show
      title="Admin de Serviço" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="status_service_admin_name" label="Status" sortable={false} />
            <TextField source="service_name" label="Serviço" />
            <TextField source="email" label="E-mail" />
            <TextField source="profile.name" label="Nome" />
            <TextField source="profile.document" label="Documento" />
            <TextField source="profile.phone" label="Telefone" />
            <TextField source="profile.responsibility" label="Cargo" />
        </SimpleShowLayout>
    </Show>
);

export default ServiceAdminShow;
