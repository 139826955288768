import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = tourPackages => {
    const data = tourPackages.map(tourPackage => ({
        id: tourPackage.id,
        titulo_pt_br: tourPackage.title.pt_br,
        titulo_en_us: tourPackage.title.en_us,
        descricao_pt_br: tourPackage.description.pt_br,
        descricao_en_us: tourPackage.description.en_us,
        site: tourPackage.site,
        criado_em: tourPackage.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'titulo_pt_br', 'titulo_en_us', 'descricao_pt_br', 'descricao_en_us',
                 'site', 'criado_em']
    });
    downloadCSV(csv, 'tour-packages');
}

export default exporter;
