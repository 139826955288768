import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = reportedProblemsCheckPoint => {
    const data = reportedProblemsCheckPoint.map(reportedProblemCheckPoint => ({
        id: reportedProblemCheckPoint.id,
        ponto_check_in: reportedProblemCheckPoint.check_point_name,
        nome: reportedProblemCheckPoint.contact_name,
        email: reportedProblemCheckPoint.contact_email,
        mensagem: reportedProblemCheckPoint.message,
        criado_em: reportedProblemCheckPoint.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'ponto_check_in', 'nome', 'email', 'mensagem', 'criado_em']
    });
    downloadCSV(csv, 'reportedProblemsCheckPoint');
}

export default exporter;
