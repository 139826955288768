import React from 'react';
import {
  Edit,
  TextInput,
  SimpleForm
} from 'react-admin';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';
import ServiceUpdateStatus from '../../ui/service-update-status';

const validateServiceUpdateEdit = (values) =>
{
  const errors = {};

  if (!values.status_service_update)
      errors.status_service_update = ['Selecione o status!'];
  if (values.status_service_update && values.status_service_update === 2 && !values.message)
      errors.message = ['Digite o motivo de recusar a atualização!'];

  return errors;
};

const ServiceUpdateEdit = (props) => (
  <Edit {...props} title={ <EditTitle subtitle="Editar Atualização de Serviço: " value="field_service_update_name" /> }>
    <SimpleForm redirect="/service-update" validate={validateServiceUpdateEdit} toolbar={<CustomEditToolbar />} >
      <TextInput source="id" label="Id" disabled />
      <TextInput source="field_service_update_name" label="Campo" fullWidth={true} disabled />
      <TextInput source="service_name" label="Serviço" fullWidth={true} disabled />
      <TextInput source="old_value" label="Valor Antigo" fullWidth={true} disabled />
      <TextInput source="new_value" label="Valor Novo" fullWidth={true} disabled />
      <ServiceUpdateStatus />
    </SimpleForm>
  </Edit>
);

export default ServiceUpdateEdit;
