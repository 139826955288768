import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = termsOfUse => {
    const data = termsOfUse.map(terms => ({
        id: terms.id,
        versao: terms.version,
        tipo: terms.resource_type,
        url: terms.secure_url,
        criado_em: terms.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'versao', 'tipo', 'url', 'criado_em']
    });
    downloadCSV(csv, 'termsOfUse');
}

export default exporter;
