import React from 'react';
import {
  required,
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput
} from 'react-admin';

const validateName = [required('Digite o nome!')];
const validateEmail = [required('Digite o e-mail!')];
const validateRoles = [required('Selecione uma permissão!')];
const validatePassword = [required('Digite a senha!')];

const AdminCreate = (props) => (
  <Create title="Novo Usuário Admin" {...props}>
    <SimpleForm redirect="/admin" >
      <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
      <TextInput source="email" label="E-mail" type="email" fullWidth={true} validate={validateEmail} />
      <TextInput source="password" label="Senha" fullWidth={true} validate={validatePassword} />
      <ReferenceArrayInput label="Permissões" source="roles" fullWidth={true} reference="setup/roles" validate={validateRoles}>
        <SelectArrayInput optionText="name" optionValue="id" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

export default AdminCreate;
