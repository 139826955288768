import React from 'react';
import {
  required,
  Edit,
  TextInput,
  SimpleForm,
  TranslatableInputs,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  BooleanInput
} from 'react-admin';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';
import RichTextInput from '../../ui/rich-text-input';
import CustomImageField from '../../ui/custom-image-field';

const validateTitle = [required('Digite o título!')];
const validateDescription = [required('Digite a descrição!')];
const validateSite = [required('Digite o site!')];
const validateImages = [required('Selecione pelo menos 1 imagem!')];

const TourPackageEdit = (props) => (
  <Edit {...props} title={ <EditTitle subtitle="Editar Pacote de Turismo: " value="title" subvalue="pt_br" /> }>
    <SimpleForm redirect="/tour-package" toolbar={<CustomEditToolbar />} >
      <TextInput source="id" label="Id" disabled />
      <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
        <TextInput source="title" label="Título" fullWidth={true} validate={validateTitle} />
        <RichTextInput source="description" label="Descrição" validate={validateDescription} />
      </TranslatableInputs>
      <TextInput source="site" label="Site" fullWidth={true} validate={validateSite} />
      <br/>
      <CustomImageField source="images" label="Imagens" showAll={true} sortable={false}/>
      <br/>
      <ReferenceArrayInput label="Imagens" perPage={5000} source="images_id" fullWidth={true} reference="image" validate={validateImages}>
        <AutocompleteArrayInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceArrayInput>
      <BooleanInput source="active" label="Ativo" />
    </SimpleForm>
  </Edit>
);

export default TourPackageEdit;
