import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = cities => {
    const data = cities.map(city => ({
        id: city.id,
        cidade: city.city.name,
        descricao_pt_br: city.description.pt_br,
        descricao_en_us: city.description.en_us,
        dicas_pt_br: city.tip.pt_br,
        dicas_en_us: city.tip.en_us,
        servicos_pt_br: city.services.pt_br,
        servicos_en_us: city.services.en_us,
        curiosidades_pt_br: city.curiosity.pt_br,
        curiosidades_en_us: city.curiosity.en_us,
        lat: city.lat,
        lng: city.lng,
        habitantes: city.number_inhabitants_format,
        criado_em: city.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'cidade', 'descricao_pt_br', 'descricao_en_us', 'dicas_pt_br',
                 'dicas_en_us', 'servicos_pt_br', 'servicos_en_us', 'curiosidades_pt_br',
                 'curiosidades_en_us', 'lat', 'lng', 'habitantes', 'criado_em']
    });
    downloadCSV(csv, 'cities');
}

export default exporter;
