import React from 'react';
import {
    Show,
    TextField,
    BooleanField,
    SimpleShowLayout
} from 'react-admin';

// own ui
import CustomImageField from '../../ui/custom-image-field';

const HighlightShow = props => (
    <Show
      title="Destaque" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <CustomImageField source="images" label="Imagem" showAll={true} sortable={false}/>
            <TextField source="title.pt_br" label="Título PT-BR" sortable={false} />
            <TextField source="title.en_us" label="Título EN-US" sortable={false} />
            <TextField source="subtitle.pt_br" label="Subtítulo PT-BR" sortable={false} />
            <TextField source="subtitle.en_us" label="Subtítulo EN-US" sortable={false} />
            <TextField source="description.pt_br" label="Descrição PT-BR" sortable={false} />
            <TextField source="description.en_us" label="Descrição EN-US" sortable={false} />
            <TextField source="publication_date_format" label="Data" sortable={false} />
            <BooleanField source="active" label="Ativo" sortable={false} />
        </SimpleShowLayout>
    </Show>
);

export default HighlightShow;
