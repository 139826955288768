import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = wayRoutes => {
    const data = wayRoutes.map(wayRoute => ({
        id: wayRoute.id,
        caminho: wayRoute.way_name,
        cidade_partida: wayRoute.city_departure.name,
        cidade_chegada: wayRoute.city_arrival.name,
        descricao_pt_br: wayRoute.description.pt_br,
        descricao_en_us: wayRoute.description.en_us,
        dificuldade_fisica: wayRoute.physical_difficulty,
        dificuldade_tecnica: wayRoute.technical_difficulty,
        distancia_total: wayRoute.total_distance,
        tem_sombra: wayRoute.shadow_presence ? 'Sim' : 'Não',
        distancia_descida: wayRoute.descent_distance,
        distancia_subida: wayRoute.ascent_distance,
        porcentagem_subida: wayRoute.ascent_medium_percentage,
        criado_em: wayRoute.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'caminho', 'cidade_partida', 'cidade_chegada', 'descricao_pt_br',
                 'descricao_en_us', 'dificuldade_fisica', 'dificuldade_tecnica', 'distancia_total', 'tem_sombra',
                 'distancia_descida', 'distancia_subida', 'porcentagem_subida', 'criado_em']
    });
    downloadCSV(csv, 'wayRoutes');
}

export default exporter;
