import React from 'react';
import {
    Show,
    TextField,
    BooleanField,
    SimpleShowLayout
} from 'react-admin';

// own ui
import CustomImageField from '../../ui/custom-image-field';

const ServiceShow = props => (
    <Show
      title="Serviço" {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <CustomImageField source="images" label="Imagem" showAll={true} sortable={false}/>
        <TextField source="status_service_name" label="Status" sortable={false} />
        <TextField source="sub_category_service_name" label="Sub Categoria" sortable={false} />
        <TextField source="company_name" label="Nome" sortable={false} />
        <TextField source="fantasy_name" label="Nome Fantasia" sortable={false} />
        <TextField source="register_number" label="CNPJ" sortable={false} />
        <TextField source="phone" label="Telefone" sortable={false} />
        <TextField source="email" label="E-mail" sortable={false} />
        <TextField source="site" label="Site" sortable={false} />
        <TextField source="facebook" label="Facebook" sortable={false} />
        <TextField source="instagram" label="Instagram" sortable={false} />
        <TextField source="description.pt_br" label="Descrição PT-BR" sortable={false} />
        <TextField source="description.en_us" label="Descrição EN-US" sortable={false} />
        <TextField source="address.city_name" label="Cidade" sortable={false} />
        <TextField source="address.street" label="Rua" sortable={false} />
        <TextField source="address.number" label="Número" sortable={false} />
        <TextField source="address.complement" label="Complemento" sortable={false} />
        <TextField source="address.zip_code" label="CEP" sortable={false} />
        <TextField source="address.neighborhood" label="Bairro" sortable={false} />
        <TextField source="address.lat" label="Latitude" sortable={false} />
        <TextField source="address.lng" label="Longitude" sortable={false} />
        <TextField source="how_to_get.pt_br" label="Como Chegar PT-BR" sortable={false} />
        <TextField source="how_to_get.en_us" label="Como Chegar EN-US" sortable={false} />
        <BooleanField source="active" label="Ativo" sortable={false} />
      </SimpleShowLayout>
    </Show>
);

export default ServiceShow;
