import React, { Fragment } from 'react';
import { change } from 'redux-form';
import {
  required,
  Edit,
  TextInput,
  SimpleForm,
  TranslatableInputs,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  FormDataConsumer,
  BooleanInput
} from 'react-admin';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';
import CustomImageField from '../../ui/custom-image-field';
import RichTextInput from '../../ui/rich-text-input';

const validateTheme = required('Selecione um tema!');
const validateTitle = [required('Digite o título!')];
const validateDescription = [required('Digite a descrição!')];
const validateState = required('Selecione um estado!');
const validateCity = required('Selecione uma cidade!');
const validateImage = required('Selecione uma imagem de capa!');

const ThemeCuriosityEdit = (props) => (
  <Edit {...props} title={ <EditTitle subtitle="Editar Destaque: " value="title" subvalue="pt_br" /> }>
    <SimpleForm redirect="/theme-curiosity" toolbar={<CustomEditToolbar />} >
      <TextInput source="id" label="Id" disabled />
      <ReferenceInput label="Tema" source="theme_id" fullWidth={true} reference="theme" validate={validateTheme}>
        <SelectInput optionText="name.pt_br" optionValue="id" />
      </ReferenceInput>
      <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
        <TextInput source="title" label="Título" fullWidth={true} validate={validateTitle} />
        <RichTextInput source="description" label="Descrição" validate={validateDescription} />
      </TranslatableInputs>
      <FormDataConsumer>
      {
        ({ formData, className, ...rest}) =>
        {
          return (
            <Fragment>
              <ReferenceInput label="Estado" source="state_id" fullWidth={true} reference="setup/states" validate={validateState} filterToQuery={ searchText => ({ q: searchText })}>
                <AutocompleteInput optionText="name" optionValue="id" onChange={() => rest.dispatch(change("", "state_id", ""))} limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }}/>
              </ReferenceInput>
              <ReferenceInput filter={{state_id: formData.state_id}} perPage={300} fullWidth={true} label="Cidade" source="city_id" reference="setup/cities" validate={validateCity}>
                <AutocompleteInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
              </ReferenceInput>
            </Fragment>
          )
        }
      }
      </FormDataConsumer>
      <CustomImageField source="image_cover" label="Imagem de Capa" sortable={false}/>
      <br/>
      <ReferenceInput label="Imagem de Capa" perPage={5000} source="image_id" fullWidth={true} reference="image" validate={validateImage}>
        <AutocompleteInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
      <BooleanInput source="active" label="Ativo" />
    </SimpleForm>
  </Edit>
);

export default ThemeCuriosityEdit;
