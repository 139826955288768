import React from 'react';
import { useMutation, Button } from 'react-admin';

const ButtonServiceAdminStatus = ({ record }) => {
    const [mutate, { loading }] = useMutation();
    const approve = event => mutate({
        type: 'update',
        resource: 'service-admin',
        payload: {
            id: record.id,
            data: { status_service_admin: 1, is_button_approve_reprove: true }
        },
    });
    const reprove = event => mutate({
        type: 'update',
        resource: 'service-admin',
        payload: {
            id: record.id,
            data: { status_service_admin: 2, is_button_approve_reprove: true }
        },
    });

    if (record.status_service_admin === 0)
        return <div>
                <Button
                   label="Aprovar"
                   onClick={approve}
                   disabled={loading}
                 />
                 <Button
                    label="Reprovar"
                    onClick={reprove}
                    disabled={loading}
                  />
               </div>
    else
        return <span>
                 {record.status_service_admin_name}
               </span>;
};

export default ButtonServiceAdminStatus;
