import React from 'react';
import { Filter, TextInput, ReferenceInput, SelectInput, BooleanInput } from 'react-admin';

const CustomFilterTextCategoryAttractiveActive = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar..." source="q" alwaysOn />
    <ReferenceInput source="category_attractive_id" label="Categoria Atrativo" reference="category-attractive" alwaysOn >
      <SelectInput optionText="name.pt_br" optionValue="id" />
    </ReferenceInput>
    <BooleanInput source="active" label="Ativo(s)" alwaysOn />
  </Filter>
)

export default CustomFilterTextCategoryAttractiveActive;
