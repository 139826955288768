import React from 'react';
import {
    Show,
    TextField,
    BooleanField,
    SimpleShowLayout
} from 'react-admin';

// own ui
import CustomImageField from '../../ui/custom-image-field';

const CopartnerShow = props => (
    <Show
      title="Parceiro" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <CustomImageField source="image" label="Imagem" showAll={true} sortable={false}/>
            <TextField source="name" label="Nome" sortable={false} />
            <TextField source="site" label="Site" sortable={false} />
            <BooleanField source="active" label="Ativo" sortable={false} />
        </SimpleShowLayout>
    </Show>
);

export default CopartnerShow;
