import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = popUps => {
    const data = popUps.map(popUp => ({
        id: popUp.id,
        titulo_pt_br: popUp.title.pt_br,
        titulo_en_us: popUp.title.en_us,
        descricao_pt_br: popUp.description.pt_br,
        descricao_en_us: popUp.description.en_us,
        inicio: popUp.start_at_format,
        fim: popUp.end_at_format,
        criado_em: popUp.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'titulo_pt_br', 'titulo_en_us', 'descricao_pt_br', 'descricao_en_us',
                 'inicio', 'fim', 'criado_em']
    });
    downloadCSV(csv, 'pop-ups');
}

export default exporter;
