import React from 'react';
import {
  required,
  Create,
  SimpleForm,
  TextInput
} from 'react-admin';

const validateName = [required('Digite o nome!')];
const validateCode = [required('Digite o código!')];

const CampaignCreate = (props) => (
  <Create title="Nova Campanha" {...props}>
    <SimpleForm redirect="/campaign" >
      <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
      <TextInput source="code" label="Código" fullWidth={true} validate={validateCode} />
    </SimpleForm>
  </Create>
);

export default CampaignCreate;
