import React from 'react';
import {
  required,
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin';

const validateName = [required('Digite o nome!')];
const validateSite = [required('Digite o site!')];
const validateImages = [required('Selecione pelo menos 1 imagem!')];

const CopartnerCreate = (props) => (
  <Create title="Novo Parceiro" {...props}>
    <SimpleForm redirect="/copartner" >
      <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
      <TextInput source="site" label="Site" fullWidth={true} validate={validateSite} />
      <ReferenceInput label="Imagem" source="image_id" fullWidth={true} reference="image" validate={validateImages}>
        <AutocompleteInput optionText="name" optionValue="id" shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default CopartnerCreate;
