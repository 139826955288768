import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = admins => {
    const data = admins.map(admin => ({
        id: admin.id,
        nome: admin.name,
        email: admin.email,
        criado_em: admin.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'nome', 'email', 'criado_em']
    });
    downloadCSV(csv, 'admins');
}

export default exporter;
