import React from 'react';
import {
  required,
  Edit,
  TextInput,
  SimpleForm,
  TranslatableInputs,
  ReferenceInput,
  SelectInput,
  NumberInput,
  BooleanInput
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';
import RichTextInput from '../../ui/rich-text-input';

export const styles =
{
  inlineWidth: { width: '20em' },
  inlineFormGroup: { display: 'inline-block', marginRight: 20 }
};

const useStyles = makeStyles(styles);
const validateDescription = [required('Digite a descrição!')];
const validateWay = required('Selecione um caminho!');
const validatePhysicalDifficulty = [required('Digite a dificuldade física!')];
const validateTechnicalDifficulty = [required('Digite a dificuldade técnica!')];
const validateTotalDistance = [required('Digite a distância total!')];
const validateDescentDistance = [required('Digite a distância de descida!')];
const validateAscentDistance = [required('Digite a distância de subida!')];
const validateAscentMediumPercentage = [required('Digite a porcentagem de subida!')];

const WayRouteEdit = (props) => {
  const classes = useStyles();

  return (
  <Edit {...props} title={ <EditTitle subtitle="Editar Rota: " value="way_name" /> }>
    <SimpleForm redirect="/way-route" toolbar={<CustomEditToolbar />} >
      <TextInput source="id" label="Id" disabled />
      <TextInput source="city_departure.name" label="Cidade de Partida" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} disabled />
      <TextInput source="city_arrival.name" label="Cidade de Chegada" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} disabled />
      <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
        <RichTextInput source="description" label="Descrição" validate={validateDescription} />
      </TranslatableInputs>
      <ReferenceInput label="Caminho" source="way_id" fullWidth={true} reference="way" validate={validateWay}>
        <SelectInput optionText="name.pt_br" optionValue="id" />
      </ReferenceInput>
      <NumberInput source="physical_difficulty" label="Dificuldade Física" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} fullWidth={true} validate={validatePhysicalDifficulty} />
      <NumberInput source="technical_difficulty" label="Dificuldade Técnica" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} fullWidth={true} validate={validateTechnicalDifficulty} />
      <NumberInput source="total_distance" label="Distância Total" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} fullWidth={true} validate={validateTotalDistance} />
      <NumberInput source="descent_distance" label="Distância de Descida" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} fullWidth={true} validate={validateDescentDistance} />
      <NumberInput source="ascent_distance" label="Distância de Subida" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} fullWidth={true} validate={validateAscentDistance} />
      <NumberInput source="ascent_medium_percentage" label="Porcentagem de Subida" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} fullWidth={true} validate={validateAscentMediumPercentage} />
      <BooleanInput source="shadow_presence" label="Tem Sombra?" fullWidth={true} />
      <BooleanInput source="reverse" label="Inverso?" fullWidth={true} />
      <BooleanInput source="active" label="Ativo" />
    </SimpleForm>
  </Edit>
)};

export default WayRouteEdit;
