import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton
} from 'react-admin';

// own ui
import CustomFilterTextServiceStatusField from '../../ui/custom-filter-text-status-field';
import ServiceUpdateExporter from '../../exporter/service-update-exporter';
import NewValueServiceUpdateField from '../../ui/new-value-service-update-field';
import rowServiceUpdateStyle from '../../ui/row-service-update-style';

const ServiceUpdateList = (props) => (
    <List
      title="Atualizações de Serviço" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterTextServiceStatusField /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ ServiceUpdateExporter } >
        <Datagrid
          rowClick="show"
          rowStyle={rowServiceUpdateStyle()}>
          <TextField source="id" sortable={true} />
          <TextField source="status_service_update_name" label="Status" sortable={false} />
          <TextField source="field_service_update_name" label="Campo" sortable={false} />
          <TextField source="service_name" label="Serviço" sortable={false} />
          <TextField source="old_value" label="Valor Antigo" sortable={false} />
          <NewValueServiceUpdateField label="Valor Novo" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
          <EditButton />
        </Datagrid>
    </List>
);

export default ServiceUpdateList;
