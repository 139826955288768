import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = licensedProducts => {
    const data = licensedProducts.map(licensedProduct => ({
        id: licensedProduct.id,
        empresa: licensedProduct.producer,
        produto: licensedProduct.product_name,
        email: licensedProduct.email,
        telefone: licensedProduct.phone,
        site: licensedProduct.site,
        criado_em: licensedProduct.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'empresa', 'produto', 'email', 'telefone', 'site', 'criado_em']
    });
    downloadCSV(csv, 'licensed-products');
}

export default exporter;
