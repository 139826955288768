import React from 'react';
import {
  required,
  Create,
  SimpleForm,
  TranslatableInputs,
  TextInput,
  ReferenceInput,
  SelectInput
} from 'react-admin';

const validateTheme = required('Selecione um tema!');
const validateName = [required('Digite o nome!')];

const SubThemeCreate = (props) => (
  <Create title="Novo Sub Tema" {...props}>
    <SimpleForm redirect="/sub-theme" >
      <ReferenceInput label="Tema" source="theme_id" fullWidth={true} reference="theme" validate={validateTheme}>
        <SelectInput optionText="name.pt_br" optionValue="id" />
      </ReferenceInput>
      <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
        <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
      </TranslatableInputs>
    </SimpleForm>
  </Create>
);

export default SubThemeCreate;
