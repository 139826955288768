import React from 'react';
import get from 'lodash/get';

const styles = {
  main: {
    display: 'flex',
    flexWrap: 'wrap'
  }
};

const LinkField = ({ record, source, ...props }) => {
    const value = get(record, source);

    if (value !== null && value !== '')
    {
      return (
        <span style={styles.main}>
        {
          <a
            href={value}
            target="_blank"
            rel="noopener noreferrer"
            onClick={ (e) => { e.stopPropagation() }}
          >{props.text ? props.text : value}</a>
        }
        </span>
      );
    }
    else
      return null
};

LinkField.defaultProps = {
  addLabel: true,
  source: 'Link'
};

export default LinkField;
