import React from 'react';
import { Filter, TextInput, SelectInput } from 'react-admin';

const CustomFilterTextServiceStatusField = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar..." source="q" alwaysOn />
    <SelectInput source="status_service_update" label="Status" choices={[
        { id: 0, name: 'Em Revisão' },
        { id: 1, name: 'Aprovado' },
        { id: 2, name: 'Reprovado' }
    ]} alwaysOn />
    <SelectInput source="field_service_update" label="Campo" choices={[
        { id: 0, name: 'Sub Categoria' },
        { id: 1, name: 'Razão Social' },
        { id: 2, name: 'Nome Fantasia' },
        { id: 3, name: 'E-mail' },
        { id: 4, name: 'Telefone' },
        { id: 5, name: 'Site' },
        { id: 6, name: 'Facebook' },
        { id: 7, name: 'Instagram' },
        { id: 8, name: 'Descrição PT_BR' },
        { id: 9, name: 'Descrição EN_US' },
        { id: 10, name: 'Imagem' },
        { id: 11, name: 'Cidade' },
        { id: 12, name: 'Rua' },
        { id: 13, name: 'Número' },
        { id: 14, name: 'Complemento' },
        { id: 15, name: 'CEP' },
        { id: 16, name: 'Bairro' },
        { id: 17, name: 'Latitude' },
        { id: 18, name: 'Longitude' }
    ]} alwaysOn />
  </Filter>
)

export default CustomFilterTextServiceStatusField;
