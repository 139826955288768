import React from 'react';
import {
  required,
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField
} from 'react-admin';

const validateName = [required('Digite o nome!')];
const validateLabel = [required('Digite a legenda!')];
const validateImage = [required('Selecione uma imagem!')];

const ImageCreate = (props) => (
  <Create title="Nova Imagem" {...props}>
    <SimpleForm redirect="/image" >
      <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
      <TextInput source="label" label="Legenda" fullWidth={true} validate={validateLabel} />
      <ImageInput source="image" label="Imagem - Tamanho Máximo: 5MB" maxSize={5000000} accept="image/png,image/gif,image/jpeg,image/jpg" validate={validateImage}>
        <ImageField src="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);

export default ImageCreate;
