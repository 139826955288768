import React from 'react';
import {
    Show,
    TextField,
    BooleanField,
    SimpleShowLayout
} from 'react-admin';

const SubThemeShow = props => (
    <Show
      title="Sub Tema" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="theme_name" label="Tema" sortable={false} />
            <TextField source="name.pt_br" label="Nome PT-BR" sortable={false} />
            <TextField source="name.en_us" label="Nome EN-US" sortable={false} />
            <BooleanField source="active" label="Ativo" sortable={false} />
        </SimpleShowLayout>
    </Show>
);

export default SubThemeShow;
