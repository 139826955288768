import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = reportedProblemsService => {
    const data = reportedProblemsService.map(reportedProblemService => ({
        id: reportedProblemService.id,
        servico: reportedProblemService.service_name,
        nome: reportedProblemService.contact_name,
        email: reportedProblemService.contact_email,
        mensagem: reportedProblemService.message,
        criado_em: reportedProblemService.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'servico', 'nome', 'email', 'mensagem', 'criado_em']
    });
    downloadCSV(csv, 'reportedProblemsService');
}

export default exporter;
