import React from 'react';
import placeholder from '../assets/placeholder.png';

export default class CustomImageField extends React.Component
{
  _renderImage = () =>
  {
    const { record, source, showAll = false } = this.props;

    if (record && record[source] && Array.isArray(record[source]) && record[source].length > 0)
    {
      if (showAll)
      {
        var imgs = [];
        record[source].forEach((image, index) =>
        {
          imgs.push(index === 0 ?
                    <img src={image.url} alt="Thumb" width="55" /> :
                    <img src={image.url} alt="Thumb" width="55" style={{ marginLeft:"10px" }} />
                  );
        });

        return (imgs);
      }
      else
      {
        return (
          <img src={record[source][0].url} alt="Thumb" width="55" />
        );
      }
    }
    else if (record && record[source] && record[source].constructor === ({}).constructor && !Array.isArray(record[source]))
    {
      return (
        <img src={record[source].url} alt="Thumb" width="55" />
      );
    }
    else if (record && record[source] && !Array.isArray(record[source]))
    {
      return (
        <img src={record[source]} alt="Thumb" width="55" />
      );
    }

    return (
      <img src={placeholder} alt="Placeholder" width="55" />
    );
  }

  render()
  {
    return (
      <span>{ this._renderImage() }</span>
    )
  }
}
