import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    BooleanField
} from 'react-admin';

// own ui
import CustomFilterTextWayActive from '../../ui/custom-filter-text-way-active';
import WayRouteExporter from '../../exporter/way-route-exporter';
import { ColorField } from 'react-admin-color-input';

const WayRouteList = (props) => (
    <List
      title="Rotas" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterTextWayActive /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ WayRouteExporter } >
        <Datagrid
          rowClick="show">
          <TextField source="id" sortable={true} />
          <TextField source="way_name" label="Caminho" sortable={false} />
          <ColorField source="city_departure.name" label="Cidade de Partida" sortable={false}/>
          <ColorField source="city_arrival.name" label="Cidade de Chegada" sortable={false}/>
          <BooleanField source="reverse" label="Inverso" sortable={false} />
          <BooleanField source="active" label="Ativo" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
          <EditButton />
        </Datagrid>
    </List>
);

export default WayRouteList;
