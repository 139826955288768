import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = checkPoints => {
    const data = checkPoints.map(checkPoint => ({
        id: checkPoint.id,
        cidade: checkPoint.city.name,
        nome_pt_br: checkPoint.name.pt_br,
        nome_en_us: checkPoint.name.en_us,
        titulo_pt_br: checkPoint.title.pt_br,
        titulo_en_us: checkPoint.title.en_us,
        descricao_pt_br: checkPoint.description.pt_br,
        descricao_en_us: checkPoint.description.en_us,
        lat: checkPoint.lat,
        lng: checkPoint.lng,
        criado_em: checkPoint.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'cidade', 'nome_pt_br', 'nome_en_us', 'titulo_pt_br', 'titulo_en_us',
                 'descricao_pt_br', 'descricao_en_us', 'lat', 'lng', 'criado_em']
    });
    downloadCSV(csv, 'checkPoints');
}

export default exporter;
