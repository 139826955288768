import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = subCategoriesAttractive => {
    const data = subCategoriesAttractive.map(subCategoryAttractive => ({
        id: subCategoryAttractive.id,
        categoria_atrativo: subCategoryAttractive.category_attractive_name,
        nome_pt_br: subCategoryAttractive.name.pt_br,
        nome_en_us: subCategoryAttractive.name.en_us,
        criado_em: subCategoryAttractive.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'categoria_atrativo', 'nome_pt_br', 'nome_en_us', 'criado_em']
    });
    downloadCSV(csv, 'subCategoriesAttractive');
}

export default exporter;
