import React from 'react';
import {
    Show,
    TextField,
    SimpleShowLayout
} from 'react-admin';

// own ui
import NewValueServiceUpdateField from '../../ui/new-value-service-update-field';

const ServiceUpdateShow = props => (
    <Show
      title="Atualização de Serviço" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="status_service_update_name" label="Status" sortable={false} />
            <TextField source="field_service_update_name" label="Campo" sortable={false} />
            <TextField source="service_name" label="Serviço" sortable={false} />
            <TextField source="old_value" label="Valor Antigo" sortable={false} />
            <NewValueServiceUpdateField label="Valor Novo" sortable={false} />
            <TextField source="message" label="Mensagem" sortable={false} />
        </SimpleShowLayout>
    </Show>
);

export default ServiceUpdateShow;
