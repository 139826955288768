import React from 'react';
import {
    Show,
    TextField,
    BooleanField,
    DateField,
    SimpleShowLayout
} from 'react-admin';

// own ui
import LinkField from '../../ui/link-field';

const ContestParticipantPhotoShow = props => (
  <Show
    title="Participante Modalidade Foto" {...props}>
    <SimpleShowLayout>
      <TextField source="contest_name" label="Concurso" sortable={false} />
      <TextField source="name" label="Nome" sortable={false} />
      <TextField source="document" label="CPF" sortable={false} />
      <TextField source="cellphone" label="Celular" sortable={false} />
      <TextField source="file_name" label="Nome Foto" sortable={false} />
      <LinkField source="url" text="Abrir" label="Foto" sortable={false}/>
      <BooleanField source="finalist" label="Finalista?" />
      <BooleanField source="winner" label="Vencedor?" />
      <TextField source="count_votes" label="Votos" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
    </SimpleShowLayout>
  </Show>
);

export default ContestParticipantPhotoShow;
