import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = privacyPolicys => {
    const data = privacyPolicys.map(privacyPolicy => ({
        id: privacyPolicy.id,
        versao: privacyPolicy.version,
        tipo: privacyPolicy.resource_type,
        url: privacyPolicy.secure_url,
        criado_em: privacyPolicy.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'versao', 'tipo', 'url', 'criado_em']
    });
    downloadCSV(csv, 'privacyPolicys');
}

export default exporter;
