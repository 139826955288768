import React from 'react';
import {
  required,
  Edit,
  TextInput,
  SimpleForm,
  TranslatableInputs,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput
} from 'react-admin';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';
import RichTextInput from '../../ui/rich-text-input';
import CustomImageField from '../../ui/custom-image-field';
import { DateTimeInput } from '../../ui/date-pickers';

const validateTitle = [required('Digite o título!')];
const validateDescription = [required('Digite a descrição!')];
const validateStartAt = [required('Digite a data de início!')];
const validateEndAt = [required('Digite a data de término!')];

const PopUpEdit = (props) => (
  <Edit {...props} title={ <EditTitle subtitle="Editar Pop Up: " value="title" subvalue="pt_br" /> }>
    <SimpleForm redirect="/pop-up" toolbar={<CustomEditToolbar />} >
      <TextInput source="id" label="Id" disabled />
      <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
        <TextInput source="title" label="Título" fullWidth={true} validate={validateTitle} />
        <RichTextInput source="description" label="Descrição" maxLength={500} validate={validateDescription} />
      </TranslatableInputs>
      <DateTimeInput source="start_at" label="Data de Início" options={{ format: 'dd/MM/yyyy, HH:mm', ampm: false, clearable: false }} isRequired validate={validateStartAt} />
      <DateTimeInput source="end_at" label="Data de Término" options={{ format: 'dd/MM/yyyy, HH:mm', ampm: false, clearable: false }} isRequired validate={validateEndAt} />
      <br/>
      <CustomImageField source="image" label="Imagem" showAll={true} sortable={false}/>
      <br/>
      <ReferenceInput label="Imagem" perPage={5000} source="image_id" fullWidth={true} reference="image">
        <AutocompleteInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
      <BooleanInput source="active" label="Ativo" />
    </SimpleForm>
  </Edit>
);

export default PopUpEdit;
