import React from 'react';
import {
  required,
  Edit,
  SimpleForm,
  TranslatableInputs,
  TextInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
  BooleanInput,
  ReferenceArrayInput,
  AutocompleteArrayInput
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';
import CustomImageField from '../../ui/custom-image-field';
import RichTextInput from '../../ui/rich-text-input';

export const styles =
{
  inlineWidth: { width: '20em' },
  inlineFormGroup: { display: 'inline-block', marginRight: 20 }
};

const useStyles = makeStyles(styles);
const validateSubCategoryAttractive = required('Selecione uma sub categoria!');
const validateSubTheme = required('Selecione um sub tema!');
const validateName = [required('Digite o nome!')];
const validateDescription = [required('Digite a descrição!')];
const validateAddress = [required('Digite o endereço!')];
const validateLat = [required('Digite a latitude!')];
const validateLng = [required('Digite a longitude!')];
const validateImages = [required('Selecione pelo menos 1 imagem!')];
const validateCities = [required('Selecione pelo menos 1 cidade!')];
const optionRendererSubCategoryAttractive = sub_category_attractive => `${sub_category_attractive.category_attractive_name} - ${sub_category_attractive.name.pt_br}`;
const optionRendererSubTheme = sub_theme => `${sub_theme.theme_name} - ${sub_theme.name.pt_br}`;
const optionRendererSubCategoryTerroirs = sub_category_terroirs => `${sub_category_terroirs.category_terroirs_name} - ${sub_category_terroirs.name.pt_br}`;

const AttractiveEdit = (props) => {
  const classes = useStyles();

  return (
  <Edit {...props} title={ <EditTitle subtitle="Editar Atrativo: " value="name" subvalue="pt_br" /> }>
    <SimpleForm redirect="/attractive" toolbar={<CustomEditToolbar />} >
      <TextInput source="id" label="Id" disabled />
      <ReferenceInput source="sub_category_attractive_id" sort={{ field: 'name_pt_br', order: 'asc' }} label="Sub Categoria" fullWidth={true} reference="sub-category-attractive" validate={validateSubCategoryAttractive} >
        <SelectInput optionText={optionRendererSubCategoryAttractive} optionValue="id" />
      </ReferenceInput>
      <ReferenceInput source="sub_theme_id" sort={{ field: 'name_pt_br', order: 'asc' }} label="Sub Tema" fullWidth={true} reference="sub-theme" validate={validateSubTheme} >
        <SelectInput optionText={optionRendererSubTheme} optionValue="id" />
      </ReferenceInput>
      <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
        <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
        <RichTextInput source="description" label="Descrição" validate={validateDescription} />
        <RichTextInput source="address" label="Endereço" validate={validateAddress} />
      </TranslatableInputs>
      <NumberInput source="lat" label="Latitude" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} validate={validateLat} />
      <NumberInput source="lng" label="Longitude" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} validate={validateLng} />
      <CustomImageField source="images" label="Imagens" showAll={true} sortable={false}/>
      <br/>
      <ReferenceArrayInput label="Imagens" perPage={5000} source="images_id" fullWidth={true} reference="image" validate={validateImages}>
        <AutocompleteArrayInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="Cidade(s)" source="cities_id" perPage={300} fullWidth={true} reference="setup/cities/with/detail" validate={validateCities}>
        <AutocompleteArrayInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceArrayInput>
      <ReferenceInput label="Sub Categoria Terroirs" sort={{ field: 'name_pt_br', order: 'asc' }} allowEmpty source="sub_category_terroirs_id" fullWidth={true} reference="sub-category-terroirs">
        <SelectInput optionText={optionRendererSubCategoryTerroirs} optionValue="id" />
      </ReferenceInput>
      <BooleanInput source="active" label="Ativo" />
    </SimpleForm>
  </Edit>
)};

export default AttractiveEdit;
