import React from 'react';
import {
  required,
  Edit,
  TextInput,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput
} from 'react-admin';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';
import CustomImageField from '../../ui/custom-image-field';

const validateProducer = [required('Digite o produtor!')];
const validateProductName = [required('Digite o produto!')];
const validateEmail = [required('Digite o e-mail!')];
const validatePhone = [required('Digite o telefone!')];
const validateSite = [required('Digite o site!')];
const validateImage = [required('Selecione uma imagem!')];

const LicensedProductEdit = (props) => (
  <Edit {...props} title={ <EditTitle subtitle="Editar Produto Licenciado: " value="product_name" /> }>
    <SimpleForm redirect="/licensed-product" toolbar={<CustomEditToolbar />} >
      <TextInput source="id" label="Id" disabled />
      <TextInput source="producer" label="Empresa" fullWidth={true} validate={validateProducer} />
      <TextInput source="product_name" label="Produto" fullWidth={true} validate={validateProductName} />
      <TextInput source="email" label="E-mail"  type="email" fullWidth={true} validate={validateEmail} />
      <TextInput source="phone" label="Telefone" fullWidth={true} validate={validatePhone} />
      <TextInput source="site" label="Site" fullWidth={true} validate={validateSite} />
      <CustomImageField source="image" label="Imagem" showAll={true} sortable={false}/>
      <br/>
      <ReferenceInput label="Imagem" perPage={5000} source="image_id" fullWidth={true} reference="image" validate={validateImage}>
        <AutocompleteInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
      <BooleanInput source="active" label="Ativo" />
    </SimpleForm>
  </Edit>
);

export default LicensedProductEdit;
