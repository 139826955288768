import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = contests => {
    const data = contests.map(contest => ({
        id: contest.id,
        nome_pt_br: contest.name.pt_br,
        nome_en_us: contest.name.en_us,
        descricao_pt_br: contest.description.pt_br,
        descricao_en_us: contest.description.en_us,
        criado_em: contest.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'nome_pt_br', 'nome_en_us', 'descricao_pt_br',
                 'descricao_en_us', 'criado_em']
    });
    downloadCSV(csv, 'contests');
}

export default exporter;
