import React from 'react';
import {
  required,
  Create,
  SimpleForm,
  TranslatableInputs,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput
} from 'react-admin';

// own ui
import RichTextInput from '../../ui/rich-text-input';

const validateTitle = [required('Digite o título!')];
const validateDescription = [required('Digite a descrição!')];
const validateSite = [required('Digite o site!')];
const validateImages = [required('Selecione pelo menos 1 imagem!')];

const TourPackageCreate = (props) => (
  <Create title="Novo Pacote de Turismo" {...props}>
    <SimpleForm redirect="/tour-package" >
      <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
        <TextInput source="title" label="Título" fullWidth={true} validate={validateTitle} />
        <RichTextInput source="description" label="Descrição" validate={validateDescription} />
      </TranslatableInputs>
      <TextInput source="site" label="Site" fullWidth={true} validate={validateSite} />
      <ReferenceArrayInput label="Imagens" source="images" fullWidth={true} reference="image" validate={validateImages}>
        <AutocompleteArrayInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

export default TourPackageCreate;
