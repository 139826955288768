import React from 'react';
import {
  required,
  Edit,
  TextInput,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput
} from 'react-admin';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';
import CustomImageField from '../../ui/custom-image-field';

const validateName = [required('Digite o nome!')];
const validateSite = [required('Digite o site!')];
const validateImages = [required('Selecione pelo menos 1 imagem!')];

const CopartnerEdit = (props) => (
  <Edit {...props} title={ <EditTitle subtitle="Editar Parceiro: " value="name" /> }>
    <SimpleForm redirect="/copartner" toolbar={<CustomEditToolbar />} >
      <TextInput source="id" label="Id" disabled />
      <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
      <TextInput source="site" label="Site" fullWidth={true} validate={validateSite} />
      <CustomImageField source="image" label="Imagem" showAll={true} sortable={false}/>
      <br/>
      <ReferenceInput label="Imagem" perPage={5000} source="image_id" fullWidth={true} reference="image" validate={validateImages}>
        <AutocompleteInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
      <BooleanInput source="active" label="Ativo" />
    </SimpleForm>
  </Edit>
);

export default CopartnerEdit;
