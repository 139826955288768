import React from 'react';
import { Filter, ReferenceInput, SelectInput } from 'react-admin';

const CustomFilterWay = (props) => (
  <Filter {...props}>
    <ReferenceInput source="way_id" label="Caminho" reference="way" allowEmpty={false} alwaysOn >
      <SelectInput optionText="name.pt_br" optionValue="id" />
    </ReferenceInput>
  </Filter>
)

export default CustomFilterWay;
