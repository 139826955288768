import React from 'react';
import {
  required,
  Create,
  SimpleForm,
  TranslatableInputs,
  TextInput,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin';

// own ui
import RichTextInput from '../../ui/rich-text-input';

const validateName = [required('Digite o nome!')];
const validateTitle = [required('Digite o título!')];
const validateDescription = [required('Digite a descrição!')];
const validateImageThumb = required('Selecione uma imagem de thumb!');
const validateImageCover = required('Selecione uma imagem de cover!');

const ThemeCreate = (props) => (
  <Create title="Novo Tema" {...props}>
    <SimpleForm redirect="/theme" >
      <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
        <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
        <TextInput source="title" label="Título" fullWidth={true} validate={validateTitle} />
        <RichTextInput source="description" label="Descrição" validate={validateDescription} />
      </TranslatableInputs>
      <ReferenceInput label="Imagem de Thumb" source="image_thumb_id" fullWidth={true} reference="image" validate={validateImageThumb}>
        <AutocompleteInput optionText="name" optionValue="id" shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
      <ReferenceInput label="Imagem de Cover" source="image_cover_id" fullWidth={true} reference="image" validate={validateImageCover}>
        <AutocompleteInput optionText="name" optionValue="id" shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default ThemeCreate;
