import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    BooleanField
} from 'react-admin';

// own ui
import CustomFilterTextCategoryAttractiveActive from '../../ui/custom-filter-text-category-attractive-active';
import SubCategoryAttractiveExporter from '../../exporter/sub-category-attractive-exporter';

const SubCategoryAttractiveList = (props) => (
    <List
      title="Sub Categorias de Atrativo" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterTextCategoryAttractiveActive /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ SubCategoryAttractiveExporter } >
        <Datagrid>
          <TextField source="id" sortable={true} />
          <TextField source="category_attractive_name" label="Categoria de Atrativo" sortable={false} />
          <TextField source="name.pt_br" label="Nome" sortable={false} />
          <BooleanField source="active" label="Ativo" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
          <EditButton />
        </Datagrid>
    </List>
);

export default SubCategoryAttractiveList;
