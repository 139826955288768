import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = contestParticipantsPhoto => {
  const data = contestParticipantsPhoto.map(contestParticipantPhoto => ({
    id: contestParticipantPhoto.id,
    concurso: contestParticipantPhoto.contest_name,
    cpf: contestParticipantPhoto.document,
    nome: contestParticipantPhoto.name,
    celular: contestParticipantPhoto.cellphone,
    finalista: (contestParticipantPhoto.finalist === true ? 'Sim' : 'Não'),
    vencedor: (contestParticipantPhoto.winner === true ? 'Sim' : 'Não'),
    nome_foto: contestParticipantPhoto.file_name,
    link: contestParticipantPhoto.url,
    criado_em: contestParticipantPhoto.created_at_format
  }));
  const csv = convertToCSV({
      data,
      fields: ['id', 'concurso', 'cpf', 'nome', 'celular', 'finalista', 'vencedor',
               'nome_foto', 'link', 'criado_em']
  });
  downloadCSV(csv, 'contestParticipantsPhoto');
}

export default exporter;
