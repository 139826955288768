import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField
} from 'react-admin';

// own ui
import CustomFilterText from '../../ui/custom-filter-text';
import LinkField from '../../ui/link-field';
import EventDaysField from '../../ui/event-days-field';
import EventSuggestionExporter from '../../exporter/event-suggestion-exporter';

const EventSuggestionList = (props) => (
    <List
      title="Eventos Sugeridos" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterText /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ EventSuggestionExporter } >
        <Datagrid
          rowClick="show">
          <TextField source="id" sortable={true} />
          <TextField source="contact_name" label="Nome" sortable={false} />
          <TextField source="name" label="Evento" sortable={false} />
          <LinkField source="cover_image_url" label="Imagem" text="Abrir" />
          <EventDaysField />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
        </Datagrid>
    </List>
);

export default EventSuggestionList;
