import React from 'react';
import {
    Show,
    TextField,
    BooleanField,
    SimpleShowLayout
} from 'react-admin';

// own ui
import CustomImageField from '../../ui/custom-image-field';

const PopUpShow = props => (
    <Show
      title="Pop Up" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <CustomImageField source="image" label="Imagem" showAll={true} sortable={false}/>
            <TextField source="title.pt_br" label="Título PT-BR" sortable={false} />
            <TextField source="title.en_us" label="Título EN-US" sortable={false} />
            <TextField source="description.pt_br" label="Descrição PT-BR" sortable={false} />
            <TextField source="description.en_us" label="Descrição EN-US" sortable={false} />
            <TextField source="start_at_format" label="Início" sortable={false} />
            <TextField source="end_at_format" label="Fim" sortable={false} />
            <BooleanField source="active" label="Ativo" sortable={false} />
        </SimpleShowLayout>
    </Show>
);

export default PopUpShow;
