import React from 'react';
import {
    Show,
    TextField,
    BooleanField,
    SimpleShowLayout
} from 'react-admin';

// own ui
import CustomImageField from '../../ui/custom-image-field';
import LinkField from '../../ui/link-field';
import EventDaysField from '../../ui/event-days-field';
import LinksField from '../../ui/links-field';

const EventSuggestionShow = props => (
  <Show
    title="Evento Sugerido" {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <CustomImageField source="cover_image_url" label="Imagem" showAll={true} />
      <LinkField source="cover_image_url" label="Link da Imagem" text="Abrir" />
      <TextField source="contact_name" label="Nome" />
      <TextField source="contact_email" label="E-mail" />
      <TextField source="contact_phone" label="Telefone" />
      <TextField source="name" label="Evento" />
      <TextField source="description" label="Descrição" />
      <BooleanField source="is_free" label="Gratuito?" />
      <TextField source="address.city_name" label="Cidade" />
      <TextField source="address.street" label="Rua" />
      <TextField source="address.number" label="Número" />
      <TextField source="address.complement" label="Complemento" />
      <TextField source="address.zip_code" label="CEP" />
      <TextField source="address.neighborhood" label="Bairro" />
      <EventDaysField />
      <LinksField />
    </SimpleShowLayout>
  </Show>
);

export default EventSuggestionShow;
