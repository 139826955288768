import React from 'react';
import {
    Show,
    TextField,
    BooleanField,
    SimpleShowLayout
} from 'react-admin';

// own ui
import CustomImageField from '../../ui/custom-image-field';

const ContestShow = props => (
    <Show
      title="Concurso" {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <CustomImageField source="image_cover" label="Capa" sortable={false}/>
        <TextField source="name.pt_br" label="Nome PT-BR" sortable={false} />
        <TextField source="name.en_us" label="Nome EN-US" sortable={false} />
        <TextField source="description.pt_br" label="Descrição PT-BR" sortable={false} />
        <TextField source="description.en_us" label="Descrição EN-US" sortable={false} />
        <TextField source="count_participants" label="Participantes" sortable={false} />
        <BooleanField source="active" label="Ativo" sortable={false} />
      </SimpleShowLayout>
    </Show>
);

export default ContestShow;
