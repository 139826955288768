import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = contestParticipantsVideo => {
  const data = contestParticipantsVideo.map(contestParticipantVideo => ({
    id: contestParticipantVideo.id,
    concurso: contestParticipantVideo.contest_name,
    cpf: contestParticipantVideo.document,
    nome: contestParticipantVideo.name,
    celular: contestParticipantVideo.cellphone,
    finalista: (contestParticipantVideo.finalist === true ? 'Sim' : 'Não'),
    vencedor: (contestParticipantVideo.winner === true ? 'Sim' : 'Não'),
    link: contestParticipantVideo.url,
    criado_em: contestParticipantVideo.created_at_format
  }));
  const csv = convertToCSV({
      data,
      fields: ['id', 'concurso', 'cpf', 'nome', 'celular', 'finalista', 'vencedor',
               'link', 'criado_em']
  });
  downloadCSV(csv, 'contestParticipantsVideo');
}

export default exporter;
