import React from 'react';
import {
  required,
  Create,
  SimpleForm,
  TranslatableInputs,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  FileInput,
  FileField
} from 'react-admin';

// own ui
import RichTextInput from '../../ui/rich-text-input';
import { DateTimeInput } from '../../ui/date-pickers';

const validateTitle = [required('Digite o título!')];
const validateSubtitle = [required('Digite o subtítulo!')];
const validateDescription = [required('Digite a descrição!')];
const validatePublicationDate = [required('Digite a data de publicação!')];
const validateImages = [required('Selecione pelo menos 1 imagem!')];

const HighlightCreate = (props) => (
  <Create title="Novo Destaque" {...props}>
    <SimpleForm redirect="/highlight">
      <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
        <TextInput source="title" label="Título" fullWidth={true} validate={validateTitle} />
        <TextInput source="subtitle" label="Subtítulo" fullWidth={true} validate={validateSubtitle} />
        <RichTextInput source="description" label="Descrição" validate={validateDescription} />
      </TranslatableInputs>
      <DateTimeInput source="publication_date" label="Data de Publicação" options={{ format: 'dd/MM/yyyy, HH:mm', ampm: false, clearable: false }} isRequired validate={validatePublicationDate} />
      <br />
      <ReferenceArrayInput label="Imagens" source="images" fullWidth={true} reference="image" validate={validateImages}>
        <AutocompleteArrayInput optionText="name" optionValue="id" shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceArrayInput>
      <FileInput source="file" label="Tamanho Máximo do Arquivo: 5MB" maxSize={5000000} accept="application/*,video/*,audio/*,image/*" >
        <FileField src="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);

export default HighlightCreate;
