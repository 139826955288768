import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    BooleanField
} from 'react-admin';

// own ui
import CustomFilterTextThemeActive from '../../ui/custom-filter-text-theme-active';
import SubThemeExporter from '../../exporter/sub-theme-exporter';

const SubThemeList = (props) => (
    <List
      title="Temas" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterTextThemeActive /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ SubThemeExporter } >
        <Datagrid
          rowClick="show">
          <TextField source="id" sortable={true} />
          <TextField source="theme_name" label="Tema" sortable={false} />
          <TextField source="name.pt_br" label="Nome" sortable={false} />
          <BooleanField source="active" label="Ativo" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
          <EditButton />
        </Datagrid>
    </List>
);

export default SubThemeList;
