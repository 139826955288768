import React from 'react';
import {
  required,
  Edit,
  TextInput,
  SimpleForm,
  TranslatableInputs,
  ReferenceInput,
  AutocompleteInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  NumberInput,
  BooleanInput
} from 'react-admin';
import { ColorInput } from 'react-admin-color-input';
import { makeStyles } from '@material-ui/core/styles';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';
import CustomImageField from '../../ui/custom-image-field';
import RichTextInput from '../../ui/rich-text-input';

export const styles =
{
  inlineWidth: { width: '20em' },
  inlineFormGroup: { display: 'inline-block', marginRight: 20 }
};

const useStyles = makeStyles(styles);
const validateCityDeparture = required('Selecione a cidade de partida!');
const validateCityArrival = required('Selecione a cidade de chegada!');
const validateName = [required('Digite o nome!')];
const validateShortName = [required('Digite o nome abreviado!')];
const validateShortDescription = [required('Digite a breve descrição!')];
const validateShortDetail = [required('Digite o breve detalhe!')];
const validateTipTravel = [required('Digite a dica do caminho!')];
const validateDescription = [required('Digite a descrição!')];
const validateTotalDistance = [required('Digite a distância total!')];
const validateTotalAscentDescentDistance = [required('Digite a distância de subida e descida!')];
const validateTotalBikeTravelDays = [required('Digite os dias de bicicleta!')];
const validateTotalWalkingTravelDays = [required('Digite os dias a pé!')];
const validateTotalCarTravelDays = [required('Digite os dias de carro!')];
const validateTotalHorseTravelDays = [required('Digite os dias de cavalo!')];
const validateTotalMotorcycleTravelDays = [required('Digite os dias de moto!')];
const validateAsphaltPercentage = [required('Digite a porcentagem de asfalto!')];
const validateTrailPercentage = [required('Digite a porcentagem de trilha!')];
const validateOffRoadPercentage = [required('Digite a porcentagem de terra!')];
const validateAsphaltDistance = [required('Digite a distância de asfalto!')];
const validateTrailDistance = [required('Digite a distância de trilha!')];
const validateOffRoadDistance = [required('Digite a distância de terra!')];
const validateColorBase = [required('Digite a cor base!')];
const validateSecondaryColor = [required('Digite a cor secundária!')];
const validateImageThumb = required('Selecione uma imagem de thumb!');
const validateImageCover = required('Selecione uma imagem de capa!');
const validateImages = required('Selecione pelo menos 1 imagem!');
const validateImageShortDetail = required('Selecione uma imagem de detalhe!');
const validateHighlights = required('Selecione pelo menos 1 destaque!');
const validateSecondaryHighlight = required('Selecione um destaque secundário!');

const WayEdit = (props) => {
  const classes = useStyles();

  return (
  <Edit {...props} title={ <EditTitle subtitle="Editar Caminho: " value="name" subvalue="pt_br" /> }>
    <SimpleForm redirect="/way" toolbar={<CustomEditToolbar />} >
      <TextInput source="id" label="Id" disabled />
      <ReferenceInput label="Cidade de Partida" perPage={300} source="city_departure_id" fullWidth={true} reference="setup/cities/with/detail" validate={validateCityDeparture}>
        <AutocompleteInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
      <ReferenceInput label="Cidade de Chegada" perPage={300} source="city_arrival_id" fullWidth={true} reference="setup/cities/with/detail" validate={validateCityArrival}>
        <AutocompleteInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
      <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
        <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
        <TextInput source="short_name" label="Nome Abreviado" fullWidth={true} validate={validateShortName} />
        <TextInput source="tip_travel" label="Dicas do Caminho" fullWidth={true} validate={validateTipTravel} />
        <TextInput source="short_text" label="Breve Detalhe" fullWidth={true} validate={validateShortDetail} />
        <RichTextInput source="short_description" label="Breve Descrição" validate={validateShortDescription} />
        <RichTextInput source="description" label="Descrição" validate={validateDescription} />
      </TranslatableInputs>
      <NumberInput source="total_distance" label="Distância Total" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} validate={validateTotalDistance} />
      <NumberInput source="ascent_descent_distance" label="Distância Subida Descida" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} validate={validateTotalAscentDescentDistance} />
      <NumberInput source="bike_travel_days" label="Dias de Bicicleta" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} validate={validateTotalBikeTravelDays} />
      <NumberInput source="walking_travel_days" label="Dias a Pé" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} validate={validateTotalWalkingTravelDays} />
      <NumberInput source="car_travel_days" label="Dias de Carro" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} validate={validateTotalCarTravelDays} />
      <NumberInput source="horse_travel_days" label="Dias de Cavalo" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} validate={validateTotalHorseTravelDays} />
      <NumberInput source="motorcycle_travel_days" label="Dias de Moto" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} validate={validateTotalMotorcycleTravelDays} />
      <NumberInput source="asphalt_percentage" label="Porcentagem de Asfalto" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} validate={validateAsphaltPercentage} />
      <NumberInput source="trail_percentage" label="Porcentagem de Trilha" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} validate={validateTrailPercentage} />
      <NumberInput source="off_road_percentage" label="Porcentagem de Terra" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} validate={validateOffRoadPercentage} />
      <NumberInput source="asphalt_distance" label="Distância de Asfalto" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} validate={validateAsphaltDistance} />
      <NumberInput source="trail_distance" label="Distância de Trilha" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} validate={validateTrailDistance} />
      <NumberInput source="off_road_distance" label="Distância de Terra" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} validate={validateOffRoadDistance} />
      <ColorInput source="color_base" label="Cor Base" validate={validateColorBase} />
      <ColorInput source="secondary_color" label="Cor Secundária" validate={validateSecondaryColor} />
      <br/>
      <br/>
      <CustomImageField source="image_thumb" label="Thumb" showAll={true} sortable={false}/>
      <br/>
      <ReferenceInput label="Imagem de Thumb" perPage={5000} source="image_thumb_id" fullWidth={true} reference="image" validate={validateImageThumb}>
        <AutocompleteInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
      <CustomImageField source="image_cover" label="Capa" showAll={true} sortable={false}/>
      <br/>
      <ReferenceInput label="Imagem de Capa" perPage={5000} source="image_cover_id" fullWidth={true} reference="image" validate={validateImageCover}>
        <AutocompleteInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
      <CustomImageField source="image_short_detail" label="Detalhe" showAll={true} sortable={false}/>
      <br/>
      <ReferenceInput label="Imagem de Detalhe" perPage={5000} source="image_short_detail_id" fullWidth={true} reference="image" validate={validateImageShortDetail}>
        <AutocompleteInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
      <CustomImageField source="images" label="Galeria" showAll={true} sortable={false}/>
      <br/>
      <ReferenceArrayInput label="Imagens da Galeria" perPage={5000} source="images_id" fullWidth={true} reference="image" validate={validateImages}>
        <AutocompleteArrayInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="Destaques" perPage={5000} source="highlights_id" fullWidth={true} reference="attractive" validate={validateHighlights}>
        <AutocompleteArrayInput optionText="name.pt_br" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceArrayInput>
      <ReferenceInput label="Destaque Secundário" perPage={5000} source="secondary_highlight_id" fullWidth={true} reference="attractive" validate={validateSecondaryHighlight}>
        <AutocompleteInput optionText="name.pt_br" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
      <BooleanInput source="active" label="Ativo" />
    </SimpleForm>
  </Edit>
)};

export default WayEdit;
