import React from 'react';
import Chip from '@material-ui/core/Chip';

const styles = {
    main: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 10,
        marginBottom: 10,
    },
    chip: { margin: 4 },
};

const RoleField = ({ record }) => {
    return (
        <span style={styles.main}>
            {record.roles &&
                record.roles.map(role => (
                    <Chip
                        key={role.id}
                        style={styles.chip}
                        label={role.name}
                    />
                ))}
        </span>
    );
};

RoleField.defaultProps = {
    addLabel: true,
    source: 'Permissões',
};

export default RoleField;
