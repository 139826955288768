import React from 'react';
import { Filter, TextInput, ReferenceInput, SelectInput, BooleanInput } from 'react-admin';

const CustomFilterTextCityStampPointActive = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar..." source="q" alwaysOn />
    <ReferenceInput source="city_id" label="Cidade" perPage={1000} reference="setup/cities/with/detail" alwaysOn >
      <SelectInput optionText="name" optionValue="id" />
    </ReferenceInput>
    <SelectInput source="stamp_point" label="Tipo" choices={[
        { id: true, name: 'Ponto de Carimbo' },
        { id: false, name: 'Marco' }
    ]} alwaysOn />
    <BooleanInput source="active" label="Ativo(s)" alwaysOn />
  </Filter>
)

export default CustomFilterTextCityStampPointActive;
