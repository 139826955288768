import React from 'react';
import Chip from '@material-ui/core/Chip';

const styles = {
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 10,
  },
  chip: { margin: 4 },
};

const CitiesField = ({ record }) => {
  return (
    <span style={styles.main}>
      {record.cities &&
        record.cities.map(city => (
            <Chip
              key={city.id}
              style={styles.chip}
              label={city.name}
            />
          ))}
    </span>
  );
};

CitiesField.defaultProps = {
  addLabel: true,
  source: 'Cidades',
};

export default CitiesField;
