import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = contacts => {
    const data = contacts.map(contact => ({
        id: contact.id,
        nome: contact.name,
        email: contact.email,
        telefone: contact.phone,
        assunto: contact.subject,
        mensagem: contact.message,
        criado_em: contact.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'nome', 'email', 'telefone', 'assunto', 'mensagem', 'criado_em']
    });
    downloadCSV(csv, 'contacts');
}

export default exporter;
