import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton
} from 'react-admin';

// own ui
import CustomFilterTextActive from '../../ui/custom-filter-text-active';
import CustomImageField from '../../ui/custom-image-field';
import CityExporter from '../../exporter/city-exporter';

const CityList = (props) => (
    <List
      title="Cidades" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterTextActive /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ CityExporter } >
        <Datagrid
          rowClick="show">
          <TextField source="id" sortable={true} />
          <CustomImageField source="image_cover" label="Capa" sortable={false}/>
          <TextField source="city_name" label="Cidade" sortable={false} />
          <TextField source="lat" label="Latitude" sortable={false} />
          <TextField source="lng" label="Longitude" sortable={false} />
          <TextField source="number_inhabitants" label="Habitantes" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
          <EditButton />
        </Datagrid>
    </List>
);

export default CityList;
