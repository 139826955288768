import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField
} from 'react-admin';

// own ui
import CustomFilterText from '../../ui/custom-filter-text';
import ContactExporter from '../../exporter/contact-exporter';

const ContactList = (props) => (
    <List
      title="Contatos" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterText /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ ContactExporter } >
        <Datagrid>
          <TextField source="id" sortable={true} />
          <TextField source="name" label="Nome" sortable={false} />
          <TextField source="email" label="E-mail" sortable={false} />
          <TextField source="phone" label="Telefone" sortable={false} />
          <TextField source="subject" label="Assunto" sortable={false} />
          <TextField source="message" label="Mensagem" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
        </Datagrid>
    </List>
);

export default ContactList;
