import React from 'react';
import {
  required,
  Create,
  SimpleForm,
  TranslatableInputs,
  TextInput
} from 'react-admin';

const validateName = [required('Digite o nome!')];

const CategoryTerroirsCreate = (props) => (
  <Create title="Nova Categoria Terroirs" {...props}>
    <SimpleForm redirect="/category-terroirs" >
      <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
        <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
      </TranslatableInputs>
    </SimpleForm>
  </Create>
);

export default CategoryTerroirsCreate;
