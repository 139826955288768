import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  BooleanField
} from 'react-admin';

// own ui
import CustomFilterTextActive from '../../ui/custom-filter-text-active';
import CampaignExporter from '../../exporter/campaign-exporter';

const CampaignList = (props) => (
    <List
      title="Campanhas" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterTextActive /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ CampaignExporter } >
        <Datagrid
          rowClick="show">
          <TextField source="id" sortable={true} />
          <TextField source="name" label="Nome" sortable={false} />
          <TextField source="code" label="Código" sortable={false} />
          <TextField source="count_used" label="Quantidade Utilizada" sortable={false} />
          <BooleanField source="active" label="Ativo" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
          <EditButton />
        </Datagrid>
    </List>
);

export default CampaignList;
