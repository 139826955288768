import React from 'react';
import {
    Show,
    TextField,
    BooleanField,
    SimpleShowLayout
} from 'react-admin';

// own ui
import CustomImageField from '../../ui/custom-image-field';

const CheckPointShow = props => (
    <Show
      title="Ponto de Check-In" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <CustomImageField source="images" label="Imagem" showAll={true} sortable={false}/>
            <TextField source="way_name" label="Caminho" sortable={false} />
            <TextField source="city.name" label="Cidade" sortable={false} />
            <TextField source="name.pt_br" label="Nome PT-BR" sortable={false} />
            <TextField source="name.en_us" label="Nome EN-US" sortable={false} />
            <TextField source="title.pt_br" label="Título PT-BR" sortable={false} />
            <TextField source="title.en_us" label="Título EN-US" sortable={false} />
            <TextField source="description.pt_br" label="Descrição PT-BR" sortable={false} />
            <TextField source="description.en_us" label="Descrição EN-US" sortable={false} />
            <TextField source="lat" label="Latitude" sortable={false} />
            <TextField source="lng" label="Longitude" sortable={false} />
            <TextField source="altitude" label="Altitude" sortable={false} />
            <TextField source="system" label="Sistema" sortable={false} />
            <TextField source="identifier" label="Identificador" sortable={false} />
            <BooleanField source="stamp_point" label="Ponto de Carimbo" sortable={false} />
            <BooleanField source="active" label="Ativo" sortable={false} />
        </SimpleShowLayout>
    </Show>
);

export default CheckPointShow;
