import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = reportedProblemsAttractive => {
    const data = reportedProblemsAttractive.map(reportedProblemAttractive => ({
        id: reportedProblemAttractive.id,
        atrativo: reportedProblemAttractive.attractive_name,
        nome: reportedProblemAttractive.contact_name,
        email: reportedProblemAttractive.contact_email,
        mensagem: reportedProblemAttractive.message,
        criado_em: reportedProblemAttractive.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'atrativo', 'nome', 'email', 'mensagem', 'criado_em']
    });
    downloadCSV(csv, 'reportedProblemsAttractive');
}

export default exporter;
