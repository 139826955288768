import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = campaigns => {
  const data = campaigns.map(campaign => ({
    id: campaign.id,
    nome: campaign.name,
    codigo: campaign.code,
    quantidade_utilizada: campaign.count_used,
    criado_em: campaign.created_at_format
  }));
  const csv = convertToCSV({
      data,
      fields: ['id', 'nome', 'codigo', 'quantidade_utilizada', 'criado_em']
  });
  downloadCSV(csv, 'campaigns');
}

export default exporter;
