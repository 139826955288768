import React from 'react';
import { Filter, TextInput, SelectInput, BooleanInput } from 'react-admin';

const CustomFilterTextServiceStatusActive = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar..." source="q" alwaysOn />
    <SelectInput source="status_service_admin" label="Status" choices={[
        { id: 0, name: 'Em Revisão' },
        { id: 1, name: 'Aprovado' },
        { id: 2, name: 'Reprovado' }
    ]} alwaysOn />
    <BooleanInput source="active" label="Ativo(s)" alwaysOn />
  </Filter>
)

export default CustomFilterTextServiceStatusActive;
