import React from 'react';
import { Filter, TextInput, ReferenceInput, SelectInput, BooleanInput } from 'react-admin';

const CustomFilterTextCategoryTerroirsActive = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar..." source="q" alwaysOn />
    <ReferenceInput source="category_terroirs_id" label="Categoria Terroirs" reference="category-terroirs" alwaysOn >
      <SelectInput optionText="name.pt_br" optionValue="id" />
    </ReferenceInput>
    <BooleanInput source="active" label="Ativo(s)" alwaysOn />
  </Filter>
)

export default CustomFilterTextCategoryTerroirsActive;
