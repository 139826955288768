import React from 'react';
import { useMutation, Link } from 'react-admin';

const ButtonServiceShow = ({ record }) => {
    const [{ loading }] = useMutation();

    return (
      <Link
       disabled={loading}
       to={'/service/' + record.id + '/show'}
       onClick={ (e) => { e.stopPropagation() }}>
       Detalhes
     </Link>
   );
};

export default ButtonServiceShow;
