import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    BooleanField
} from 'react-admin';

// own ui
import CustomFilterTextActive from '../../ui/custom-filter-text-active';
import CustomImageField from '../../ui/custom-image-field';
import HighlightExporter from '../../exporter/highlight-exporter';

const HighlightList = (props) => (
    <List
      title="Destaques" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterTextActive /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ HighlightExporter } >
        <Datagrid
          rowClick="show">
          <TextField source="id" sortable={true} />
          <CustomImageField source="images" label="Imagem" sortable={false}/>
          <TextField source="title.pt_br" label="Título" sortable={false} />
          <TextField source="subtitle.pt_br" label="Subtítulo" sortable={false} />
          <TextField source="publication_date_format" label="Data" sortable={false} />
          <BooleanField source="active" label="Ativo" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
          <EditButton />
        </Datagrid>
    </List>
);

export default HighlightList;
