import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    BooleanField
} from 'react-admin';

// own ui
import CustomFilterTextActive from '../../ui/custom-filter-text-active';
import CustomImageField from '../../ui/custom-image-field';
import LicensedProductExporter from '../../exporter/licensed-product-exporter';

const LicensedProductList = (props) => (
    <List
      title="Produtos Licenciados" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterTextActive /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ LicensedProductExporter } >
        <Datagrid
          rowClick="show">
          <TextField source="id" sortable={true} />
          <CustomImageField source="image" label="Imagem" sortable={false}/>
          <TextField source="producer" label="Empresa" sortable={false} />
          <TextField source="product_name" label="Produto" sortable={false} />
          <TextField source="email" label="E-mail" sortable={false} />
          <TextField source="phone" label="Telefone" sortable={false} />
          <TextField source="site" label="Site" sortable={false} />
          <BooleanField source="active" label="Ativo" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
          <EditButton />
        </Datagrid>
    </List>
);

export default LicensedProductList;
