import React from 'react';
import {
  required,
  Edit,
  TextInput,
  SimpleForm,
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput
} from 'react-admin';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';

const validateName = [required('Digite o nome!')];
const validateRole = [required('Selecione uma permissão!')];

const AdminEdit = (props) => (
  <Edit {...props} title={ <EditTitle subtitle="Editar Usuário Admin: " value="email" /> }>
    <SimpleForm redirect="/admin" toolbar={<CustomEditToolbar />} >
      <TextInput source="id" label="Id" disabled />
      <TextInput source="email" label="E-mail" fullWidth={true} disabled />
      <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
      <TextInput source="password" fullWidth={true} label="Senha" />
      <ReferenceArrayInput label="Permissões" source="roles_id" fullWidth={true} reference="setup/roles" validate={validateRole}>
        <SelectArrayInput optionText="name" optionValue="id" />
      </ReferenceArrayInput>
      <BooleanInput source="active" label="Ativo" />
    </SimpleForm>
  </Edit>
);

export default AdminEdit;
