import React from 'react';
import {
  required,
  Create,
  SimpleForm,
  FileInput,
  FileField,
  ReferenceInput,
  SelectInput
} from 'react-admin';

const validateWayRoute = required('Selecione uma rota!');
const validateTypeFile = required('Selecione o tipo de arquivo!');
const validateFile = required('Selecione um arquivo!');
const optionRenderer = way_route => way_route ? `${way_route.way_name} - ${way_route.city_departure.name} - ${way_route.city_arrival.name}` : '';

const WayRouteFilesCreate = (props) => (
  <Create title="Novo Arquivo da Rota" {...props}>
    <SimpleForm redirect="/way-route-files" >
      <ReferenceInput source="way_route_id" label="Rota" perPage={500} reference="way-route" fullWidth={true} allowEmpty={false} validate={validateWayRoute}>
        <SelectInput optionText={optionRenderer} optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
      <ReferenceInput label="Tipo de Arquivo" source="type_file" fullWidth={true} reference="setup/types-file" filter={{ type_model: 'way_route' }} validate={validateTypeFile}>
        <SelectInput optionText="name" optionValue="type" />
      </ReferenceInput>
      <FileInput source="file" label="Tamanho Máximo do Arquivo: 5MB" maxSize={5000000} validate={validateFile}>
        <FileField src="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);

export default WayRouteFilesCreate;
