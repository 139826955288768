import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField
} from 'react-admin';

// own ui
import CustomFilterText from '../../ui/custom-filter-text';
import EnterpriseContactExporter from '../../exporter/enterprise-contact-exporter';

const ContactList = (props) => (
    <List
      title="Contatos Empresariais" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterText /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ EnterpriseContactExporter } >
        <Datagrid>
          <TextField source="id" sortable={true} />
          <TextField source="type_enterprise_contact.name" label="Tipo" sortable={false} />
          <TextField source="enterprise_name" label="Empresa" sortable={false} />
          <TextField source="email_owner" label="E-mail" sortable={false} />
          <TextField source="phone_owner" label="Telefone" sortable={false} />
          <TextField source="enterprise_register_number" label="CNPJ" sortable={false} />
          <TextField source="site" label="Site" sortable={false} />
          <TextField source="description" label="Mensagem" sortable={false} />
          <TextField source="city.name" label="Cidade" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
        </Datagrid>
    </List>
);

export default ContactList;
