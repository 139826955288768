import React from 'react';
import {
  required,
  Edit,
  SimpleForm,
  TranslatableInputs,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';
import CustomImageField from '../../ui/custom-image-field';
import RichTextInput from '../../ui/rich-text-input';

export const styles =
{
  inlineWidth: { width: '20em' },
  inlineFormGroup: { display: 'inline-block', marginRight: 20 }
};

const useStyles = makeStyles(styles);
const validateDescription = [required('Digite a descrição!')];
const validateImage = required('Selecione uma imagem de capa!');
const validateImageFigure = required('Selecione uma imagem de figurinha!');
const validateImages = [required('Selecione pelo menos 1 imagem!')];
const validateLat = [required('Digite a latitude!')];
const validateLng = [required('Digite a longitude!')];
const validateNumberInhabitants = [required('Digite o númeor de Habitantes!')];

const CityEdit = (props) => {
  const classes = useStyles();

  return (
  <Edit {...props} title={ <EditTitle subtitle="Editar Cidade: " value="city_name" /> }>
    <SimpleForm redirect="/city" toolbar={<CustomEditToolbar />} >
      <TextInput source="id" label="Id" disabled />
      <TextInput source="city_state_name" label="Estado" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} disabled />
      <TextInput source="city_name" label="Cidade" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} disabled />
      <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
        <RichTextInput source="description" label="Descrição" validate={validateDescription} />
        <RichTextInput source="tip" label="Dicas" />
        <RichTextInput source="services" label="Serviços" />
        <TextInput source="curiosity" label="Curiosidade" fullWidth={true} />
      </TranslatableInputs>
      <br/>
      <NumberInput source="lat" label="Latitude" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} validate={validateLat} />
      <NumberInput source="lng" label="Longitude" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} validate={validateLng} />
      <NumberInput source="number_inhabitants" label="Número de Habitantes" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} validate={validateNumberInhabitants} />
      <CustomImageField source="image_cover" label="Capa" showAll={true} sortable={false}/>
      <br/>
      <ReferenceInput label="Imagem de Capa" perPage={5000} source="image_id" fullWidth={true} reference="image" validate={validateImage}>
        <AutocompleteInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
      <CustomImageField source="image_figure" label="Figurinha" showAll={true} sortable={false}/>
      <br/>
      <ReferenceInput label="Imagem de Figurinha" perPage={5000} source="image_figure_id" fullWidth={true} reference="image" validate={validateImageFigure}>
        <AutocompleteInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
      <CustomImageField source="images" label="Imagens" showAll={true} sortable={false}/>
      <br/>
      <ReferenceArrayInput label="Imagens" perPage={5000} source="images_id" fullWidth={true} reference="image" validate={validateImages}>
        <AutocompleteArrayInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
)};

export default CityEdit;
