import React from 'react';
import {
  required,
  Edit,
  TextInput,
  SimpleForm,
  BooleanInput
} from 'react-admin';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';

const validateName = [required('Digite o nome!')];
const validateCode = [required('Digite o código!')];

const CampaignEdit = (props) => (
  <Edit
    {...props}
    mutationMode="pessimistic"
    title={ <EditTitle subtitle="Editar Campanha: " value="name" /> }>
    <SimpleForm redirect="/campaign" toolbar={<CustomEditToolbar />} >
      <TextInput source="id" label="Id" disabled />
      <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
      <TextInput source="code" label="Código" fullWidth={true} validate={validateCode} />
      <BooleanInput source="active" label="Ativo" />
    </SimpleForm>
  </Edit>
);

export default CampaignEdit;
