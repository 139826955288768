import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = attractives => {
    const data = attractives.map(attractive => ({
        id: attractive.id,
        sub_categoria: attractive.sub_category_attractive_name,
        sub_tema: attractive.sub_theme_name,
        nome_pt_br: attractive.name.pt_br,
        nome_en_us: attractive.name.en_us,
        descricao_pt_br: attractive.description.pt_br,
        descricao_en_us: attractive.description.en_us,
        endereco_pt_br: attractive.address.pt_br,
        endereco_en_us: attractive.address.en_us,
        lat: attractive.lat,
        lng: attractive.lng,
        sub_categoria_terroirs: attractive.sub_category_terroirs_name,
        criado_em: attractive.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'sub_categoria', 'sub_tema', 'nome_pt_br', 'nome_en_us', 'descricao_pt_br', 'descricao_en_us',
                 'endereco_pt_br', 'endereco_en_us', 'lat', 'lng', 'sub_categoria_terroirs', 'criado_em']
    });
    downloadCSV(csv, 'attractives');
}

export default exporter;
