import React from 'react';
import {
  required,
  Create,
  SimpleForm,
  TranslatableInputs,
  TextInput,
  ReferenceInput,
  SelectInput
} from 'react-admin';

const validateCategoryAttractive = required('Selecione uma categoria de atrativo!');
const validateName = [required('Digite o nome!')];

const SubCategoryAttractiveCreate = (props) => (
  <Create title="Nova Sub Categoria Atrativo" {...props}>
    <SimpleForm redirect="/sub-category-attractive" >
      <ReferenceInput label="Categoria de Atrativo" source="category_attractive_id" fullWidth={true} reference="category-attractive" validate={validateCategoryAttractive}>
        <SelectInput optionText="name.pt_br" optionValue="id" />
      </ReferenceInput>
      <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
        <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
      </TranslatableInputs>
    </SimpleForm>
  </Create>
);

export default SubCategoryAttractiveCreate;
