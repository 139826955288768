import React from 'react';
import { Filter, TextInput, BooleanInput } from 'react-admin';

const CustomFilterTextActive = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar..." source="q" alwaysOn />
    <BooleanInput source="active" label="Ativo(s)" alwaysOn />
  </Filter>
)

export default CustomFilterTextActive;
