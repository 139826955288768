import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = categoriesTerroirs => {
    const data = categoriesTerroirs.map(categoryTerroirs => ({
        id: categoryTerroirs.id,
        nome_pt_br: categoryTerroirs.name.pt_br,
        nome_en_us: categoryTerroirs.name.en_us,
        criado_em: categoryTerroirs.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'nome_pt_br', 'nome_en_us', 'criado_em']
    });
    downloadCSV(csv, 'categoriesTerroirs');
}

export default exporter;
