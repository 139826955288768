import React from 'react';
import {
  required,
  Edit,
  TabbedForm,
  FormTab,
  TranslatableInputs,
  TextInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  NumberInput,
  BooleanInput,
  ReferenceArrayInput,
  AutocompleteArrayInput
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';
import CustomImageField from '../../ui/custom-image-field';
import RichTextInput from '../../ui/rich-text-input';

export const styles =
{
  inlineWidth: { width: '20em' },
  inlineFormGroup: { display: 'inline-block', marginRight: 20 }
};

const useStyles = makeStyles(styles);
const validateSubCategoryService = required('Selecione uma sub categoria!');
const validateName = [required('Digite a razão social!')];
const validateFantasyName = [required('Digite o nome fantasia!')];
const validateEmail = [required('Digite o e-mail!')];
const validateCity = [required('Selecione uma cidade!')];
const validateStreet = [required('Digite a rua!')];
const optionRendererSubCategoryService = sub_category_service => `${sub_category_service.category_service_name} - ${sub_category_service.name.pt_br}`;

const ServiceEdit = (props) => {
  const classes = useStyles();

  return (
  <Edit {...props} title={ <EditTitle subtitle="Editar Serviço: " value="company_name" subvalue="pt_br" /> }>
    <TabbedForm redirect="/service" toolbar={<CustomEditToolbar />} >
      <FormTab label="Dados">
        <TextInput source="id" label="Id" disabled />
        <ReferenceInput source="sub_category_service_id" sort={{ field: 'name_pt_br', order: 'asc' }} label="Sub Categoria" fullWidth={true} reference="sub-category-service" validate={validateSubCategoryService} >
          <SelectInput optionText={optionRendererSubCategoryService} optionValue="id" />
        </ReferenceInput>
        <TextInput source="company_name" label="Razão Social" fullWidth={true} validate={validateName} />
        <TextInput source="fantasy_name" label="Nome Fantasia" fullWidth={true} validate={validateFantasyName} />
        <TextInput source="email" label="E-mail" fullWidth={true} validate={validateEmail} />
        <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
          <RichTextInput source="description" label="Descrição" />
        </TranslatableInputs>
        <TextInput source="phone" label="Telefone" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} fullWidth={true} />
        <TextInput source="site" label="Site" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} fullWidth={true} />
        <BooleanInput source="active" label="Ativo" />
      </FormTab>
      <FormTab label="Endereço">
        <ReferenceInput label="Cidade" perPage={300} source="address.city_id" fullWidth={true} reference="setup/cities/with/detail" validate={validateCity}>
          <AutocompleteInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
        </ReferenceInput>
        <TextInput source="address.street" label="Rua" fullWidth={true} validate={validateStreet} />
        <TextInput source="address.number" label="Número" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} fullWidth={true} />
        <TextInput source="address.zip_code" label="CEP" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} fullWidth={true} />
        <TextInput source="address.complement" label="Complemento" fullWidth={true} />
        <TextInput source="address.neighborhood" label="Bairro" fullWidth={true} />
        <NumberInput source="address.lat" label="Latitude" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} />
        <NumberInput source="address.lng" label="Longitude" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} />
        <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
          <RichTextInput source="how_to_get" label="Como Chegar" />
        </TranslatableInputs>
      </FormTab>
      <FormTab label="Social e Imagens">
        <TextInput source="facebook" label="Facebook" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} fullWidth={true} />
        <TextInput source="instagram" label="Instagram" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} fullWidth={true} />
        <CustomImageField source="images" label="Imagens" showAll={true} sortable={false}/>
        <br/>
        <ReferenceArrayInput label="Imagens" perPage={5000} source="images_id" fullWidth={true} reference="image">
          <AutocompleteArrayInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
        </ReferenceArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
)};

export default ServiceEdit;
