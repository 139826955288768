import React from 'react';
import { Filter, TextInput } from 'react-admin';

const CustomFilterText = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar..." source="q" alwaysOn />
  </Filter>
)

export default CustomFilterText;
