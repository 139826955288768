import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    BooleanField
} from 'react-admin';

// own ui
import CustomFilterTextActive from '../../ui/custom-filter-text-active';
import AdminExporter from '../../exporter/admin-exporter';
import RoleField from '../../ui/role-field';

const AdminList = (props) => (
    <List
      title="Usuários Admin" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterTextActive /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ AdminExporter } >
        <Datagrid
          rowClick="show">
          <TextField source="id" sortable={true} />
          <TextField source="name" label="Nome" sortable={false} />
          <TextField source="email" label="E-mail" sortable={false} />
          <RoleField label="Permissões" sortable={false} />
          <BooleanField source="active" label="Ativo" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
          <EditButton />
        </Datagrid>
    </List>
);

export default AdminList;
