import React from 'react';
import {
  required,
  minLength,
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin';

const validateService = [required('Selecione uma empresa!')];
const validateName = [required('Digite o nome!')];
const validateEmail = [required('Digite o e-mail!')];
const validateDocument = [required('Digite o CPF!')];
const validatePhone = [required('Digite o Telefone!'), minLength(15, 'Telefone (DD) DDDDD-DDDD')];
const optionRendererService = service => service ? `${service.fantasy_name} - ${service.address.city_name}` : ``;

const ServiceAdminCreate = (props) => (
  <Create title="Novo Admin de Serviço" {...props}>
    <SimpleForm redirect="/service-admin" >
      <ReferenceInput fullWidth={true} label="Serviço" source="service_id" reference="service/not/admin" validate={validateService}>
        <AutocompleteInput optionText={optionRendererService} optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
      <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
      <TextInput source="email" label="E-mail" type="email" fullWidth={true} validate={validateEmail} />
      <TextInput source="document" label="Documento" fullWidth={true} validate={validateDocument} />
      <TextInput source="phone" label="Telefone (DD) DDDDD-DDDD" fullWidth={true} validate={validatePhone} />
      <TextInput source="responsibility" label="Cargo" fullWidth={true} />
    </SimpleForm>
  </Create>
);

export default ServiceAdminCreate;
