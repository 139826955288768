import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = services => {
    const data = services.map(service => ({
        id: service.id,
        sub_categoria: service.sub_category_service_name,
        razao_social: service.company_name,
        nome_fantasia: service.fantasy_name,
        cnpj: service.register_number,
        telefone: service.phone,
        email: service.email,
        site: service.site,
        facebook: service.facebook,
        instagram: service.instagram,
        responsavel: service.responsible,
        cargo_responsavel: service.responsible_position,
        descricao_pt_br: service.description.pt_br,
        descricao_en_us: service.description.en_us,
        cidade: service.address.city_name,
        rua: service.address.street,
        number: service.address.number,
        complemento: service.address.complement ? service.address.complement : '',
        cep: service.address.zip_code,
        bairro: service.address.neighborhood,
        lat: service.address.lat,
        lng: service.address.lng,
        como_chegar_pt_br: service.how_to_get.pt_br,
        como_chegar_en_us: service.how_to_get.en_us,
        criado_em: service.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'sub_categoria', 'razao_social', 'nome_fantasia', 'cnpj',
                 'telefone', 'email', 'site', 'facebook', 'instagram', 'responsavel',
                 'cargo_responsavel', 'descricao_pt_br', 'descricao_en_us', 'cidade',
                 'rua', 'number', 'complemento', 'cep', 'bairro', 'lat', 'lng',
                 'como_chegar_pt_br', 'como_chegar_en_us', 'criado_em']
    });
    downloadCSV(csv, 'services');
}

export default exporter;
