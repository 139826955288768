import React from 'react';
import {
    Show,
    TextField,
    SimpleShowLayout
} from 'react-admin';

// own ui
import CustomImageField from '../../ui/custom-image-field';

const CityShow = props => (
    <Show
      title="Cidade" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <CustomImageField source="image_cover" label="Capa" showAll={true} sortable={false}/>
            <CustomImageField source="image_figure" label="Figurinha" showAll={true} sortable={false}/>
            <CustomImageField source="images" label="Imagem" showAll={true} sortable={false}/>
            <TextField source="city_name" label="Cidade" sortable={false} />
            <TextField source="description.pt_br" label="Descrição PT-BR" sortable={false} />
            <TextField source="description.en_us" label="Descrição EN-US" sortable={false} />
            <TextField source="tip.pt_br" label="Dicas PT" sortable={false} />
            <TextField source="tip.en_us" label="Dicas EN" sortable={false} />
            <TextField source="services.pt_br" label="Serviços PT" sortable={false} />
            <TextField source="services.en_us" label="Serviços EN" sortable={false} />
            <TextField source="curiosity.pt_br" label="Curiosidades PT" sortable={false} />
            <TextField source="curiosity.en_us" label="Curiosidades EN" sortable={false} />
            <TextField source="lat" label="Latitude" sortable={false} />
            <TextField source="lng" label="Longitude" sortable={false} />
            <TextField source="number_inhabitants" label="Habitantes" sortable={false} />
        </SimpleShowLayout>
    </Show>
);

export default CityShow;
