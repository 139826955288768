import React from 'react';
import { Filter, TextInput, ReferenceInput, SelectInput, BooleanInput } from 'react-admin';

const CustomFilterTextSubCategoryServiceStatusActive = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar..." source="q" alwaysOn />
    <ReferenceInput source="sub_category_service_id" label="Sub Categoria" reference="sub-category-service" alwaysOn >
      <SelectInput optionText="name.pt_br" optionValue="id" />
    </ReferenceInput>
    <SelectInput source="status_service" label="Status" choices={[
        { id: 0, name: 'Em Revisão' },
        { id: 1, name: 'Aprovado' },
        { id: 2, name: 'Reprovado' }
    ]} alwaysOn />
    <BooleanInput source="active" label="Ativo(s)" alwaysOn />
  </Filter>
)

export default CustomFilterTextSubCategoryServiceStatusActive;
