import React from 'react';
import {
  required,
  Create,
  SimpleForm,
  TranslatableInputs,
  TextInput,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin';

// own ui
import RichTextInput from '../../ui/rich-text-input';
import { DateTimeInput } from '../../ui/date-pickers';

const validateTitle = [required('Digite o título!')];
const validateDescription = [required('Digite a descrição!')];
const validateStartAt = [required('Digite a data de início!')];
const validateEndAt = [required('Digite a data de término!')];

const PopUpCreate = (props) => (
  <Create title="Novo Pop Up" {...props}>
    <SimpleForm redirect="/pop-up">
      <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
        <TextInput source="title" label="Título" fullWidth={true} validate={validateTitle} />
        <RichTextInput source="description" label="Descrição" maxLength={500} validate={validateDescription} />
      </TranslatableInputs>
      <DateTimeInput source="start_at" label="Data de Início" options={{ format: 'dd/MM/yyyy, HH:mm', ampm: false, clearable: false }} isRequired validate={validateStartAt} />
      <DateTimeInput source="end_at" label="Data de Término" options={{ format: 'dd/MM/yyyy, HH:mm', ampm: false, clearable: false }} isRequired validate={validateEndAt} />
      <br />
      <ReferenceInput label="Imagem" source="image" fullWidth={true} reference="image">
        <AutocompleteInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default PopUpCreate;
