import React from 'react';
import {
  Edit,
  TextInput,
  SelectInput,
  BooleanInput,
  SimpleForm
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';

export const styles =
{
  inlineWidth: { width: '20em' },
  inlineFormGroup: { display: 'inline-block', marginRight: 20 }
};

const useStyles = makeStyles(styles);

const CustomerEdit = (props) =>
{
  const classes = useStyles();

  return (
  <Edit
    {...props}
    mutationMode="pessimistic"
    title={ <EditTitle subtitle="Editar Usuário: " value="email" /> }>
    <SimpleForm
      redirect="/customer"
      toolbar={<CustomEditToolbar />} >
      <TextInput source="id" label="Id" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} disabled />
      <TextInput source="passport.number" label="Passaporte" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} disabled />
      <TextInput source="document" label="CPF" fullWidth={true} disabled />
      <TextInput source="email" label="E-mail" fullWidth={true} />
      <TextInput source="profile.name" label="Nome" fullWidth={true} />
      <TextInput source="profile.phone" label="Telefone" fullWidth={true} />
      <TextInput source="profile.birthdate" label="Data de Nascimento" fullWidth={true} />
      <SelectInput source="profile.gender.type" label="Gênero" choices={[
          { id: 0, name: 'Feminino' },
          { id: 1, name: 'Masculino' }
      ]} />
      <BooleanInput source="active" label="Ativo" />
    </SimpleForm>
  </Edit>
)};

export default CustomerEdit;
