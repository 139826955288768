import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = highlights => {
    const data = highlights.map(highlight => ({
        id: highlight.id,
        titulo_pt_br: highlight.title.pt_br,
        titulo_en_us: highlight.title.en_us,
        subtitulo_pt_br: highlight.subtitle.pt_br,
        subtitulo_en_us: highlight.subtitle.en_us,
        descricao_pt_br: highlight.description.pt_br,
        descricao_en_us: highlight.description.en_us,
        data: highlight.publication_date_format,
        criado_em: highlight.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'titulo_pt_br', 'titulo_en_us', 'subtitulo_pt_br', 'subtitulo_en_us',
                 'descricao_pt_br', 'descricao_en_us', 'data', 'criado_em']
    });
    downloadCSV(csv, 'highlights');
}

export default exporter;
