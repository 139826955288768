import React from 'react';
import {
  Edit,
  TextInput,
  SimpleForm,
  BooleanInput
} from 'react-admin';

// own ui
import EditTitle from '../../ui/edit-title';
import LinkField from '../../ui/link-field';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';

const ContestParticipantVideoEdit = (props) => (
  <Edit
    {...props}
    mutationMode="pessimistic"
    title={ <EditTitle subtitle="Editar Participação Modalidade Vídeo: " value="name" /> }>
    <SimpleForm redirect="/contest-participant-video" toolbar={<CustomEditToolbar />} >
      <TextInput source="id" label="Id" disabled />
      <TextInput source="contest_name" label="Concurso" fullWidth={true} disabled />
      <TextInput source="name" label="Nome" fullWidth={true} disabled />
      <TextInput source="document" label="CPF" fullWidth={true} disabled />
      <TextInput source="cellphone" label="Celular" fullWidth={true} disabled />
      <LinkField source="url" text="Abrir" label="Vídeo" sfullWidth={true} />
      <br />
      <BooleanInput source="finalist" label="Finalista?" />
      <BooleanInput source="winner" label="Vencedor?" />
    </SimpleForm>
  </Edit>
);

export default ContestParticipantVideoEdit;
