import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    BooleanField
} from 'react-admin';

// own ui
import CustomFilterTextActive from '../../ui/custom-filter-text-active';
import CustomImageField from '../../ui/custom-image-field';
import PopUpExporter from '../../exporter/pop-up-exporter';

const PopUpList = (props) => (
    <List
      title="Pop Up's" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterTextActive /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ PopUpExporter } >
        <Datagrid
          rowClick="show">
          <TextField source="id" sortable={true} />
          <CustomImageField source="image" label="Imagem" sortable={false}/>
          <TextField source="title.pt_br" label="Título" sortable={false} />
          <TextField source="start_at_format" label="Início" sortable={false} />
          <TextField source="end_at_format" label="Fim" sortable={false} />
          <BooleanField source="active" label="Ativo" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
          <EditButton />
        </Datagrid>
    </List>
);

export default PopUpList;
