import React from 'react';
import {
    Show,
    TextField,
    BooleanField,
    SimpleShowLayout
} from 'react-admin';

// own ui
import CustomImageField from '../../ui/custom-image-field';
import EventDaysField from '../../ui/event-days-field';

const EventShow = props => (
    <Show
      title="Evento" {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <CustomImageField source="images" label="Imagem" showAll={true} sortable={false}/>
        <TextField source="name.pt_br" label="Nome PT-BR" sortable={false} />
        <TextField source="name.en_us" label="Nome EN-US" sortable={false} />
        <TextField source="description.pt_br" label="Descrição PT-BR" sortable={false} />
        <TextField source="description.en_us" label="Descrição EN-US" sortable={false} />
        <TextField source="more_details.pt_br" label="Detalhes PT-BR" sortable={false} />
        <TextField source="more_details.en_us" label="Detalhes EN-US" sortable={false} />
        <BooleanField source="is_free" label="Gratuito?" sortable={false} />
        <TextField source="address.city_name" label="Cidade" sortable={false} />
        <TextField source="address.street" label="Rua" sortable={false} />
        <TextField source="address.number" label="Número" sortable={false} />
        <TextField source="address.complement" label="Complemento" sortable={false} />
        <TextField source="address.zip_code" label="CEP" sortable={false} />
        <TextField source="address.neighborhood" label="Bairro" sortable={false} />
        <TextField source="address.lat" label="Latitude" sortable={false} />
        <TextField source="address.lng" label="Longitude" sortable={false} />
        <BooleanField source="active" label="Ativo" sortable={false} />
        <EventDaysField />
      </SimpleShowLayout>
    </Show>
);

export default EventShow;
