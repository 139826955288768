import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = images => {
    const data = images.map(image => ({
        id: image.id,
        url: image.url,
        nome: image.name,
        legenda: image.label,
        criado_em: image.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'url', 'nome', 'legenda', 'criado_em']
    });
    downloadCSV(csv, 'images');
}

export default exporter;
