import React from 'react';
import {
    Show,
    TextField,
    RichTextField,
    DateField,
    SimpleShowLayout
} from 'react-admin';

// own ui
import LinkField from '../../ui/link-field';

const ServiceReportedProblemShow = props => (
    <Show
      title="Problema em Serviço" {...props}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="service_name" label="Serviço" sortable={false} />
          <TextField source="contact_name" label="Nome" sortable={false} />
          <TextField source="contact_email" label="E-mail" sortable={false} />
          <RichTextField source="message" label="Mensagem" sortable={false} />
          <LinkField source="image.url" text="Abrir" label="Imagem" sortable={false}/>
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
        </SimpleShowLayout>
    </Show>
);

export default ServiceReportedProblemShow;
