import React from 'react';
import {
  required,
  Edit,
  TextInput,
  SimpleForm,
  TranslatableInputs,
  BooleanInput
} from 'react-admin';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';

const validateName = [required('Digite o nome!')];

const CategoryTerroirsEdit = (props) => (
  <Edit {...props} title={ <EditTitle subtitle="Editar Categoria Terroirs: " value="name" subvalue="pt_br" /> }>
    <SimpleForm redirect="/category-terroirs" toolbar={<CustomEditToolbar />} >
      <TextInput source="id" label="Id" disabled />
      <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
        <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
      </TranslatableInputs>
      <br/>
      <BooleanInput source="active" label="Ativo" />
    </SimpleForm>
  </Edit>
);

export default CategoryTerroirsEdit;
