import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField
} from 'react-admin';

// own ui
import CustomFilterText from '../../ui/custom-filter-text';
import LinkField from '../../ui/link-field';
import ReportedProblemServiceExporter from '../../exporter/reported-problem-service-exporter';

const ReportedProblemServiceList = (props) => (
    <List
      title="Problemas em Serviços" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterText /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ ReportedProblemServiceExporter } >
        <Datagrid
          rowClick="show">
          <TextField source="id" sortable={true} />
          <TextField source="service_name" label="Serviço" sortable={false} />
          <TextField source="contact_name" label="Nome" sortable={false} />
          <TextField source="contact_email" label="E-mail" sortable={false} />
          <LinkField source="image.url" text="Abrir" label="Imagem" sortable={false}/>
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
        </Datagrid>
    </List>
);

export default ReportedProblemServiceList;
