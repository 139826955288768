import React from 'react';
import {
  required,
  Edit,
  SimpleForm,
  TranslatableInputs,
  TextInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
  BooleanInput,
  ReferenceArrayInput,
  AutocompleteArrayInput
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';
import CustomImageField from '../../ui/custom-image-field';
import RichTextInput from '../../ui/rich-text-input';

export const styles =
{
  inlineWidth: { width: '20em' },
  inlineFormGroup: { display: 'inline-block', marginRight: 20 }
};

const useStyles = makeStyles(styles);
const validateWay = required('Selecione um caminho!');
const validateCity = required('Selecione uma cidade!');
const validateName = [required('Digite o nome!')];
const validateTitle = [required('Digite o título!')];
const validateDescription = [required('Digite a descrição!')];
const validateLat = [required('Digite a latitude!')];
const validateLng = [required('Digite a longitude!')];
const validateImages = [required('Selecione pelo menos 1 imagem!')];

const CheckPointEdit = (props) => {
  const classes = useStyles();

  return (
  <Edit {...props} title={ <EditTitle subtitle="Editar Ponto de Check-In: " value="name" subvalue="pt_br" /> }>
    <SimpleForm redirect="/check-point" toolbar={<CustomEditToolbar />} >
      <TextInput source="id" label="Id" disabled />
      <ReferenceInput source="way_id" label="Caminho" fullWidth={true} reference="way" validate={validateWay} >
        <SelectInput optionText="name.pt_br" optionValue="id" />
      </ReferenceInput>
      <ReferenceInput source="city_id" label="Cidade" fullWidth={true} reference="setup/cities/with/detail" validate={validateCity} >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
        <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
        <TextInput source="title" label="Título" fullWidth={true} validate={validateTitle} />
        <RichTextInput source="description" label="Descrição" validate={validateDescription} />
      </TranslatableInputs>
      <NumberInput source="lat" label="Latitude" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} validate={validateLat} />
      <NumberInput source="lng" label="Longitude" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} validate={validateLng} />
      <CustomImageField source="images" label="Imagens" showAll={true} sortable={false}/>
      <br/>
      <ReferenceArrayInput label="Imagens" perPage={5000} source="images_id" fullWidth={true} reference="image" validate={validateImages}>
        <AutocompleteArrayInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceArrayInput>
      <TextInput source="altitude" label="Altitude" fullWidth={true} />
      <TextInput source="system" label="Sistema" fullWidth={true} />
      <TextInput source="identifier" label="Identificador" fullWidth={true} />
      <BooleanInput source="stamp_point" label="Ponto de Carimbo" />
      <BooleanInput source="active" label="Ativo" />
    </SimpleForm>
  </Edit>
)};

export default CheckPointEdit;
