import React from 'react';
import { Filter, TextInput, ReferenceInput, SelectInput, BooleanInput } from 'react-admin';

const CustomFilterTextThemeActive = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar..." source="q" alwaysOn />
    <ReferenceInput source="theme_id" label="Tema" reference="theme" alwaysOn >
      <SelectInput optionText="name.pt_br" optionValue="id" />
    </ReferenceInput>
    <BooleanInput source="active" label="Ativo(s)" alwaysOn />
  </Filter>
)

export default CustomFilterTextThemeActive;
