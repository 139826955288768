import React from 'react';
import { Filter, TextInput, ReferenceInput, SelectInput } from 'react-admin';

const CustomFilterContestParticipantPhoto = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar..." source="q" alwaysOn />
    <ReferenceInput source="contest_id" label="Concurso" perPage={500} reference="setup/contests" allowEmpty={false} alwaysOn >
      <SelectInput optionText="name.pt_br" optionValue="id" />
    </ReferenceInput>
    <SelectInput source="finalist" label="Status" choices={[
        { id: true, name: 'Finalista' },
        { id: false, name: 'Não Finalista' }
    ]} alwaysOn />
  </Filter>
)

export default CustomFilterContestParticipantPhoto;
