import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    BooleanField
} from 'react-admin';

// own ui
import CustomFilterText from '../../ui/custom-filter-text';
import LinkField from '../../ui/link-field';
import TermsOfUseExporter from '../../exporter/terms-of-use-exporter';

const TermsOfUseList = (props) => (
    <List
      title="Termos de Uso" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterText /> }
      bulkActionButtons={ false }
      exporter={ TermsOfUseExporter } >
        <Datagrid>
          <TextField source="id" sortable={true} />
          <TextField source="version" label="Versão" sortable={false} />
          <TextField source="resource_type" label="Tipo" sortable={false} />
          <LinkField source="url" text="Ver" label="Arquivo" sortable={false}/>
          <BooleanField source="active" label="Ativo" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
        </Datagrid>
    </List>
);

export default TermsOfUseList;
