import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = ways => {
    const data = ways.map(way => ({
        id: way.id,
        nome_pt_br: way.name.pt_br,
        nome_en_us: way.name.en_us,
        breve_descricao_pt_br: way.short_description.pt_br,
        breve_descricao_en_us: way.short_description.en_us,
        descricao_pt_br: way.description.pt_br,
        descricao_en_us: way.description.en_us,
        breve_detalhe_pt_br: way.short_text.pt_br,
        breve_detalhe_en_us: way.short_text.en_us,
        distancia: way.total_distance,
        distancia_subida_descida: way.ascent_descent_distance,
        dias_bicicleta: way.bike_travel_days,
        dias_andando: way.walking_travel_days,
        dias_carro: way.car_travel_days,
        dias_cavalo: way.horse_travel_days,
        dias_moto: way.motorcycle_travel_days,
        porcentagem_asfalto: way.asphalt_percentage,
        porcentagem_trilha: way.trail_percentage,
        porcentagem_terra: way.off_road_percentage,
        distancia_asfalto: way.asphalt_distance,
        distancia_trilha: way.trail_distance,
        distancia_terra: way.off_road_distance,
        dica_pt_br: way.tip_travel.pt_br,
        dica_en_us: way.tip_travel.en_us,
        criado_em: way.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'nome_pt_br', 'nome_en_us', 'breve_descricao_pt_br', 'breve_descricao_en_us', 'descricao_pt_br',
                 'descricao_en_us', 'breve_detalhe_pt_br', 'breve_detalhe_en_us',  'distancia', 'distancia_subida_descida',
                 'dias_bicicleta', 'dias_andando', 'dias_carro', 'dias_cavalo', 'dias_moto', 'porcentagem_asfalto',
                 'porcentagem_trilha', 'porcentagem_terra', 'distancia_asfalto', 'distancia_trilha', 'distancia_terra',
                 'dica_pt_br', 'dica_en_us', 'criado_em']
    });
    downloadCSV(csv, 'ways');
}

export default exporter;
