import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useInput, FieldTitle } from 'ra-core';
import { DatePicker, TimePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBRLocale from "date-fns/locale/pt-BR";
import moment from 'moment';
import MomentUtils from '@date-io/moment'

const Picker = ({ PickerComponent, ...fieldProps }) => {

  const {
    options,
    label,
    source,
    resource,
    className,
    isRequired,
    validate,
    providerOptions,
  } = fieldProps;

  const { input, meta } = useInput({ source, validate });

  const { touched, error } = meta;

  const handleChange = useCallback(value =>
  {
    // TIME INPUT
    if (fieldProps.options.isTimeInput)
      Date.parse(value) ? input.onChange(moment(value).format('HH:mm')) : input.onChange(null);
    // DATE INPUT OR DATE TIME INPUT
    else
      Date.parse(value) ? input.onChange(moment(value).format()) : input.onChange(null);
  }, [input, fieldProps.options.isTimeInput]);

  var date = null;

  // TIME INPUT
  if (fieldProps.options.isTimeInput)
  {
    if (input.value)
    {
      var dateAux = new Date();
      dateAux.setHours(input.value.split(":")[0], input.value.split(":")[1], 0);
      date = dateAux;
    }
  }
  // DATE INPUT OR DATE TIME INPUT
  else
    date = input.value ? moment(input.value.slice(0, 16)).format() : null;

  return (
    <div className="picker">
      <MuiPickersUtilsProvider utils={MomentUtils} {...providerOptions}>
        <PickerComponent
          {...options}
          label={<FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />}
          margin="normal"
          error={!!(touched && error)}
          helperText={touched && error}
          className={className}
          value={date}
          onChange={date => handleChange(date)}
          onBlur={() => input.onBlur(date)}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}

Picker.propTypes = {
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  labelTime: PropTypes.string,
  className: PropTypes.string,
  providerOptions: PropTypes.shape({
    utils: PropTypes.func,
    locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
};

Picker.defaultProps = {
  input: {},
  isRequired: false,
  meta: { touched: false, error: false },
  options: {},
  resource: '',
  source: '',
  labelTime: '',
  className: '',
  providerOptions: {
    utils: DateFnsUtils,
    locale: ptBRLocale,
  },
};

export const DateInput = props => <Picker PickerComponent={DatePicker} {...props} />
export const TimeInput = props => <Picker PickerComponent={TimePicker} {...props} />
export const DateTimeInput = props => <Picker PickerComponent={DateTimePicker} {...props} />
