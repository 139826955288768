import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = subThemes => {
    const data = subThemes.map(subTheme => ({
        id: subTheme.id,
        tema: subTheme.theme_name,
        nome_pt_br: subTheme.name.pt_br,
        nome_en_us: subTheme.name.en_us,
        criado_em: subTheme.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'tema', 'nome_pt_br', 'nome_en_us', 'criado_em']
    });
    downloadCSV(csv, 'subThemes');
}

export default exporter;
