import React from 'react';
import {
  required,
  Edit,
  SimpleForm,
  TranslatableInputs,
  TextInput,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';
import CustomImageField from '../../ui/custom-image-field';
import RichTextInput from '../../ui/rich-text-input';

const validateDescription = [required('Digite a descrição!')];
const validateImage = required('Selecione uma imagem de capa!');

const ContestEdit = (props) => (
  <Edit {...props} title={ <EditTitle subtitle="Editar Concurso: " value="name" subvalue="pt_br" /> }>
    <SimpleForm redirect="/contest" toolbar={<CustomEditToolbar />} >
      <TextInput source="id" label="Id" disabled />
      <TextInput source="name.pt_br" label="Nome" disabled />
      <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
        <RichTextInput source="description" label="Descrição" validate={validateDescription} />
      </TranslatableInputs>
      <CustomImageField source="image_cover" label="Capa" showAll={true} sortable={false}/>
      <br/>
      <ReferenceInput label="Imagem de Capa" perPage={5000} source="image_id" fullWidth={true} reference="image" validate={validateImage}>
        <AutocompleteInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default ContestEdit;
