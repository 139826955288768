import React, { Fragment } from 'react';
import { change } from 'redux-form';
import {
  required,
  Create,
  SimpleForm,
  TranslatableInputs,
  TextInput,
  ReferenceArrayInput,
  NumberInput,
  ReferenceInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  FormDataConsumer
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

// own ui
import RichTextInput from '../../ui/rich-text-input';

export const styles =
{
  inlineWidth: { width: '20em' },
  inlineFormGroup: { display: 'inline-block', marginRight: 20 }
};

const useStyles = makeStyles(styles);
const validateState = required('Selecione um estado!');
const validateCity = required('Selecione uma cidade!');
const validateDescription = [required('Digite a descrição!')];
const validateImage = required('Selecione uma imagem de capa!');
const validateImageFigure = required('Selecione uma imagem de figurinha!');
const validateImages = [required('Selecione pelo menos 1 imagem!')];
const validateLat = [required('Digite a latitude!')];
const validateLng = [required('Digite a longitude!')];
const validateNumberInhabitants = [required('Digite o númeor de Habitantes!')];

const CityCreate = (props) => {
  const classes = useStyles();

  return (
  <Create title="Nova Cidade" {...props}>
    <SimpleForm redirect="/city">
      <FormDataConsumer>
      {
        ({ formData, className, ...rest}) =>
        {
          return (
            <Fragment>
              <ReferenceInput label="Estado" source="state_id" fullWidth={true} reference="setup/states" validate={validateState} filterToQuery={ searchText => ({ q: searchText })}>
                <AutocompleteInput optionText="name" optionValue="id" onChange={() => rest.dispatch(change("", "state_id", ""))} limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }}/>
              </ReferenceInput>
              <ReferenceInput filter={{state_id: formData.state_id}} fullWidth={true} label="Cidade" source="city_id" reference="setup/cities/not/detail" validate={validateCity}>
                <AutocompleteInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
              </ReferenceInput>
            </Fragment>
          )
        }
      }
      </FormDataConsumer>
      <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
        <RichTextInput source="description" label="Descrição" validate={validateDescription} />
        <RichTextInput source="tip" label="Dicas" />
        <RichTextInput source="services" label="Serviços" />
        <TextInput source="curiosity" label="Curiosidade" fullWidth={true} />
      </TranslatableInputs>
      <br/>
      <NumberInput source="lat" label="Latitude" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} validate={validateLat} />
      <NumberInput source="lng" label="Longitude" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} validate={validateLng} />
      <NumberInput source="number_inhabitants" label="Número de Habitantes" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} validate={validateNumberInhabitants} />
      <ReferenceInput label="Imagem de Capa" source="image_id" fullWidth={true} reference="image" validate={validateImage}>
        <AutocompleteInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
      <ReferenceInput label="Imagem de Figurinha" source="image_figure_id" fullWidth={true} reference="image" validate={validateImageFigure}>
        <AutocompleteInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
      <ReferenceArrayInput label="Imagens" source="images" fullWidth={true} reference="image" validate={validateImages}>
        <AutocompleteArrayInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
)};

export default CityCreate;
