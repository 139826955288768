import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField
} from 'react-admin';

// own ui
import CustomFilterText from '../../ui/custom-filter-text';
import NewsletterExporter from '../../exporter/newsletter-exporter';

const NewsletterList = (props) => (
    <List
      title="Newsletters" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterText /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ NewsletterExporter } >
        <Datagrid>
          <TextField source="id" sortable={true} />
          <TextField source="email" label="E-mail" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
        </Datagrid>
    </List>
);

export default NewsletterList;
