import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    BooleanField
} from 'react-admin';

// own ui
import CustomFilterTextCategoryTerroirsActive from '../../ui/custom-filter-text-category-terroirs-active';
import SubCategoryTerroirsExporter from '../../exporter/sub-category-terroirs-exporter';

const SubCategoryTerroirsList = (props) => (
    <List
      title="Sub Categorias Terroirs" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterTextCategoryTerroirsActive /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ SubCategoryTerroirsExporter } >
        <Datagrid>
          <TextField source="id" sortable={true} />
          <TextField source="category_terroirs_name" label="Categoria Terroirs" sortable={false} />
          <TextField source="name.pt_br" label="Nome" sortable={false} />
          <BooleanField source="active" label="Ativo" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
          <EditButton />
        </Datagrid>
    </List>
);

export default SubCategoryTerroirsList;
