import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton
} from 'react-admin';

// own ui
import CustomFilterTextSubCategoryServiceStatusActive from '../../ui/custom-filter-text-sub-category-service-status-active';
import CustomImageField from '../../ui/custom-image-field';
import ServiceExporter from '../../exporter/service-exporter';
import ButtonServiceStatus from '../../ui/button-service-status';
import ButtonServiceShow from '../../ui/button-service-show';
import rowServiceStyle from '../../ui/row-service-style';

const ServiceList = (props) => (
    <List
      title="Serviços" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterTextSubCategoryServiceStatusActive /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ ServiceExporter } >
        <Datagrid
          rowStyle={rowServiceStyle()}>
          <TextField source="id" sortable={true} />
          <CustomImageField source="images" label="Imagem" sortable={false}/>
          <TextField source="sub_category_service_name" label="Sub Categoria" sortable={false} />
          <TextField source="company_name" label="Razão Social" sortable={false} />
          <TextField source="fantasy_name" label="Nome Fantasia" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
          <ButtonServiceStatus label="Status" />
          <ButtonServiceShow label="Ver" />
          <EditButton />
        </Datagrid>
    </List>
);

export default ServiceList;
