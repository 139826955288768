import React from 'react';
import {
  required,
  Create,
  SimpleForm,
  FileInput,
  FileField
} from 'react-admin';

const validateFile = [required('Selecione um arquivo!')];

const StampPointsFileCreate = (props) => (
  <Create title="Novo Arquivo de Pontos de Carimbo" {...props}>
    <SimpleForm redirect="/stamp-points-file">
      <FileInput source="file" label="Tamanho Máximo do Arquivo: 5MB" maxSize={5000000} accept="application/*,video/*,audio/*,image/*"  validate={validateFile} >
        <FileField src="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);

export default StampPointsFileCreate;
