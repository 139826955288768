import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    BooleanField
} from 'react-admin';

// own ui
import CustomFilterCustomer from '../../ui/custom-filter-customer';
import CustomerExporter from '../../exporter/customer-exporter';

const CustomerList = (props) => (
    <List
      title="Usuários" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterCustomer /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ CustomerExporter } >
        <Datagrid
          rowClick="show">
          <TextField source="id" sortable={true} />
          <TextField source="passport.number" label="Passaporte" sortable={false} />
          <TextField source="profile.name" label="Nome" sortable={false} />
          <TextField source="email" label="E-mail" sortable={false} />
          <TextField source="campaign.name" label="Campanha" sortable={false} />
          <BooleanField source="active" label="Ativo" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
          <EditButton />
        </Datagrid>
    </List>
);

export default CustomerList;
