import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = updatesServiceAdmin => {
    const data = updatesServiceAdmin.map(updateServiceAdmin => ({
        id: updateServiceAdmin.id,
        status: updateServiceAdmin.status_service_update_name,
        campo: updateServiceAdmin.field_service_update_name,
        servico: updateServiceAdmin.service_name,
        valor_antigo: updateServiceAdmin.old_value,
        valor_novo: updateServiceAdmin.new_value,
        mensagem: updateServiceAdmin.message,
        criado_em: updateServiceAdmin.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'status', 'campo', 'servico', 'valor_antigo', 'valor_novo',
                 'mensagem', 'criado_em']
    });
    downloadCSV(csv, 'updatesServiceAdmin');
}

export default exporter;
