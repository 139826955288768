import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  EditButton
} from 'react-admin';

// own ui
import CustomFilterContestParticipantPhoto from '../../ui/custom-filter-contest-participant-photo';
import LinkField from '../../ui/link-field';
import ContestParticipantPhotoExporter from '../../exporter/contest-participant-photo-exporter';

const ContestParticipantPhotoList = (props) => (
    <List
      title="Participantes Modalidade Foto" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterContestParticipantPhoto /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ ContestParticipantPhotoExporter } >
        <Datagrid
          rowClick="show">
          <TextField source="id" sortable={true} />
          <TextField source="contest_name" label="Concurso" sortable={false} />
          <TextField source="name" label="Nome" sortable={false} />
          <TextField source="document" label="CPF" sortable={false} />
          <TextField source="cellphone" label="Celular" sortable={false} />
          <TextField source="file_name" label="Nome Foto" sortable={false} />
          <LinkField source="url" text="Abrir" label="Foto" sortable={false}/>
          <BooleanField source="finalist" label="Finalista?" />
          <BooleanField source="winner" label="Vencedor?" />
          <TextField source="count_votes" label="Votos" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" showTime sortable={false} />
          <EditButton />
        </Datagrid>
    </List>
);

export default ContestParticipantPhotoList;
