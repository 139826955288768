import React from 'react';
import {
  required,
  Edit,
  TextInput,
  SimpleForm,
  TranslatableInputs,
  ReferenceInput,
  SelectInput,
  BooleanInput
} from 'react-admin';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';

const validateCategoryTerroirs = required('Selecione uma categoria terrois!');
const validateName = [required('Digite o nome!')];

const SubCategoryTerroirsEdit = (props) => (
  <Edit {...props} title={ <EditTitle subtitle="Editar Sub Categoria Terroirs: " value="name" subvalue="pt_br" /> }>
    <SimpleForm redirect="/sub-category-terroirs" toolbar={<CustomEditToolbar />} >
      <TextInput source="id" label="Id" disabled />
      <ReferenceInput label="Categoria Terroirs" source="category_terroirs_id" fullWidth={true} reference="category-terroirs" validate={validateCategoryTerroirs}>
        <SelectInput optionText="name.pt_br" optionValue="id" />
      </ReferenceInput>
      <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
        <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
      </TranslatableInputs>
      <br/>
      <BooleanInput source="active" label="Ativo" />
    </SimpleForm>
  </Edit>
);

export default SubCategoryTerroirsEdit;
