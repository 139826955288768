import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = newsletters => {
    const data = newsletters.map(newsletter => ({
        id: newsletter.id,
        email: newsletter.email,
        criado_em: newsletter.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'email', 'criado_em']
    });
    downloadCSV(csv, 'newsletters');
}

export default exporter;
