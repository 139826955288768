import React from 'react';
import { Filter, ReferenceInput, SelectInput } from 'react-admin';

const optionRenderer = way_route => `${way_route.way_name} - ${way_route.city_departure.name} - ${way_route.city_arrival.name}`;

const CustomFilterWayRoute = (props) => (
  <Filter {...props}>
    <ReferenceInput source="way_route_id" label="Rota" perPage={500} reference="way-route" allowEmpty={false} alwaysOn >
      <SelectInput optionText={optionRenderer} optionValue="id" />
    </ReferenceInput>
  </Filter>
)

export default CustomFilterWayRoute;
