import React from 'react';
import {
    Show,
    TextField,
    SimpleShowLayout
} from 'react-admin';

// own ui
import RoleField from '../../ui/role-field';

const AdminShow = props => (
    <Show
      title="Usuário Admin" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" label="Nome" />
            <TextField source="email" label="E-mail" />
            <RoleField label="Permissões" sortable={false}/>
        </SimpleShowLayout>
    </Show>
);

export default AdminShow;
