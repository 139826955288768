import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    BooleanField
} from 'react-admin';

// own ui
import CustomFilterTextSubCategoryAttractiveActive from '../../ui/custom-filter-text-sub-category-attractive-active';
import CustomImageField from '../../ui/custom-image-field';
import CitiesField from '../../ui/cities-field';
import AttractiveExporter from '../../exporter/attractive-exporter';

const AttractiveList = (props) => (
    <List
      title="Atrativos" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterTextSubCategoryAttractiveActive /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ AttractiveExporter } >
        <Datagrid
          rowClick="show">
          <TextField source="id" sortable={true} />
          <CustomImageField source="images" label="Imagem" sortable={false}/>
          <TextField source="sub_category_attractive_name" label="Sub Categoria" sortable={false} />
          <TextField source="sub_theme_name" label="Sub Tema" sortable={false} />
          <TextField source="name.pt_br" label="Nome" sortable={false} />
          <TextField source="sub_category_terroirs_name" label="Sub Categoria Terroirs" sortable={false} />
          <CitiesField label="Cidades" sortable={false}/>
          <BooleanField source="active" label="Ativo" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
          <EditButton />
        </Datagrid>
    </List>
);

export default AttractiveList;
