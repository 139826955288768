import React from 'react';
import {
  required,
  Edit,
  TextInput,
  SimpleForm,
  TranslatableInputs,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput
} from 'react-admin';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';
import CustomImageField from '../../ui/custom-image-field';
import RichTextInput from '../../ui/rich-text-input';

const validateName = [required('Digite o nome!')];
const validateTitle = [required('Digite o título!')];
const validateDescription = [required('Digite a descrição!')];
const validateImageThumb = required('Selecione uma imagem de thumb!');
const validateImageCover = required('Selecione uma imagem de cover!');

const ThemeEdit = (props) => (
  <Edit {...props} title={ <EditTitle subtitle="Editar Tema: " value="name" subvalue="pt_br" /> }>
    <SimpleForm redirect="/theme" toolbar={<CustomEditToolbar />} >
      <TextInput source="id" label="Id" disabled />
      <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
        <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
        <TextInput source="title" label="Título" fullWidth={true} validate={validateTitle} />
        <RichTextInput source="description" label="Descrição" validate={validateDescription} />
      </TranslatableInputs>
      <br/>
      <br/>
      <CustomImageField source="image_thumb" label="Imagem de Thumb" showAll={true} sortable={false}/>
      <br/>
      <ReferenceInput label="Imagem de Thumb" perPage={5000} source="image_thumb_id" fullWidth={true} reference="image" validate={validateImageThumb}>
        <AutocompleteInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
      <CustomImageField source="image_cover" label="Imagem de Cover" showAll={true} sortable={false}/>
      <br/>
      <ReferenceInput label="Imagem de Cover" perPage={5000} source="image_cover_id" fullWidth={true} reference="image" validate={validateImageCover}>
        <AutocompleteInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
      <BooleanInput source="active" label="Ativo" />
    </SimpleForm>
  </Edit>
);

export default ThemeEdit;
