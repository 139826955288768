import React from 'react';
import {
  maxLength
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const toolbarOptions = [ [{ 'header': [false, 2, 3] }], ['bold', 'italic', 'underline', 'strike', 'link', 'blockquote', 'code-block'], [{ 'color': [] }, { 'background': [] }], [{ 'list': 'ordered'}, { 'list': 'bullet' }], [{ 'script': 'sub'}, { 'script': 'super' }], ['image', 'video'] ];

const CustomRichTextInput = (props) => {

  if (props.maxLength)
    return <RichTextInput {...props} toolbar={toolbarOptions} validate={[maxLength(props.maxLength)]} />
  else
    return <RichTextInput {...props} toolbar={toolbarOptions} />
}

export default CustomRichTextInput;
