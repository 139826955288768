import React from 'react';
import { Admin, Resource } from 'react-admin';

import Dashboard from './pages/dashboard';
import Login from './pages/login';
import Layout from './layout/layout';

import dataProvider from './provider/dataProvider';
import authProvider from './provider/authProvider';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import portugueseMessages from 'ra-language-portuguese';
import config from './config/config';

// ADMIN
import AdminIcon from '@material-ui/icons/SupervisorAccount';
import AdminList from './container/admin/list';
import AdminCreate from './container/admin/create';
import AdminEdit from './container/admin/edit';
import AdminShow from './container/admin/show';

// CAMPAIGN
import CampaignIcon from '@material-ui/icons/RecordVoiceOver';
import CampaignList from './container/campaign/list';
import CampaignCreate from './container/campaign/create';
import CampaignEdit from './container/campaign/edit';
import CampaignShow from './container/campaign/show';

// CUSTOMER
import CustomerIcon from '@material-ui/icons/SupervisedUserCircle';
import CustomerList from './container/customer/list';
import CustomerEdit from './container/customer/edit';
import CustomerShow from './container/customer/show';

// SERVICE ADMIN
import ServiceAdminIcon from '@material-ui/icons/VerifiedUser';
import ServiceAdminList from './container/service-admin/list';
import ServiceAdminCreate from './container/service-admin/create';
import ServiceAdminEdit from './container/service-admin/edit';
import ServiceAdminShow from './container/service-admin/show';

// IMAGE
import ImageIcon from '@material-ui/icons/InsertPhoto';
import ImageList from './container/image/list';
import ImageCreate from './container/image/create';
import ImageEdit from './container/image/edit';

// CONTACT
import ContactIcon from '@material-ui/icons/ContactMail';
import ContactList from './container/contact/list';

// ENTERPRISE CONTACT
import EnterpriseContactIcon from '@material-ui/icons/Contacts';
import EnterpriseContactList from './container/enterprise-contact/list';

// NEWSLETTER
import NewsletterIcon from '@material-ui/icons/Subscriptions';
import NewsletterList from './container/newsletter/list';

// LICENSED PRODUCT
import LicensedProductIcon from '@material-ui/icons/MonetizationOn';
import LicensedProductList from './container/licensed-product/list';
import LicensedProductCreate from './container/licensed-product/create';
import LicensedProductEdit from './container/licensed-product/edit';
import LicensedProductShow from './container/licensed-product/show';

// TOUR PACKAGE
import TourPackageIcon from '@material-ui/icons/FlightTakeoff';
import TourPackageList from './container/tour-package/list';
import TourPackageCreate from './container/tour-package/create';
import TourPackageEdit from './container/tour-package/edit';
import TourPackageShow from './container/tour-package/show';

// COPARTNER
import CopartnerIcon from '@material-ui/icons/GroupWork';
import CopartnerList from './container/copartner/list';
import CopartnerCreate from './container/copartner/create';
import CopartnerEdit from './container/copartner/edit';
import CopartnerShow from './container/copartner/show';

// HIGHLIGHT
import HighlightIcon from '@material-ui/icons/Highlight';
import HighlightList from './container/highlight/list';
import HighlightCreate from './container/highlight/create';
import HighlightEdit from './container/highlight/edit';
import HighlightShow from './container/highlight/show';

// THEME
import ThemeIcon from '@material-ui/icons/AddToQueue';
import ThemeList from './container/theme/list';
import ThemeCreate from './container/theme/create';
import ThemeEdit from './container/theme/edit';
import ThemeShow from './container/theme/show';

// THEME CURIOSITY
import ThemeCuriosityIcon from '@material-ui/icons/CastForEducation';
import ThemeCuriosityList from './container/theme-curiosity/list';
import ThemeCuriosityCreate from './container/theme-curiosity/create';
import ThemeCuriosityEdit from './container/theme-curiosity/edit';
import ThemeCuriosityShow from './container/theme-curiosity/show';

// SUB THEME
import SubThemeIcon from '@material-ui/icons/Subject';
import SubThemeList from './container/sub-theme/list';
import SubThemeCreate from './container/sub-theme/create';
import SubThemeEdit from './container/sub-theme/edit';
import SubThemeShow from './container/sub-theme/show';

// CITY
import CityIcon from '@material-ui/icons/LocationCity';
import CityList from './container/city/list';
import CityCreate from './container/city/create';
import CityEdit from './container/city/edit';
import CityShow from './container/city/show';

// WAY
import WayIcon from '@material-ui/icons/Subway';
import WayList from './container/way/list';
import WayEdit from './container/way/edit';
import WayShow from './container/way/show';

// WAY FILES
import WayFilesIcon from '@material-ui/icons/FileCopy';
import WayFilesList from './container/way-files/list';
import WayFilesCreate from './container/way-files/create';

// WAY ROUTE
import WayRouteIcon from '@material-ui/icons/Directions';
import WayRouteList from './container/way-route/list';
import WayRouteCreate from './container/way-route/create';
import WayRouteEdit from './container/way-route/edit';
import WayRouteShow from './container/way-route/show';

// WAY ROUTE FILES
import WayRouteFilesIcon from '@material-ui/icons/InsertDriveFile';
import WayRouteFilesList from './container/way-route-files/list';
import WayRouteFilesCreate from './container/way-route-files/create';

// CHECK POINT
import CheckPointIcon from '@material-ui/icons/PinDrop';
import CheckPointList from './container/check-point/list';
import CheckPointCreate from './container/check-point/create';
import CheckPointEdit from './container/check-point/edit';
import CheckPointShow from './container/check-point/show';

// CHECK POINT REPORTED PROBLEM
import CheckPointReportedProblemIcon from '@material-ui/icons/ReportProblem';
import CheckPointReportedProblemList from './container/check-point-reported-problem/list';
import CheckPointReportedProblemShow from './container/check-point-reported-problem/show';

// CATEGORY TERROIRS
import CategoryTerroirsIcon from '@material-ui/icons/Assignment';
import CategoryTerroirsList from './container/category-terroirs/list';
import CategoryTerroirsCreate from './container/category-terroirs/create';
import CategoryTerroirsEdit from './container/category-terroirs/edit';

// SUB CATEGORY TERROIRS
import SubCategoryTerroirsIcon from '@material-ui/icons/ClosedCaption';
import SubCategoryTerroirsList from './container/sub-category-terroirs/list';
import SubCategoryTerroirsCreate from './container/sub-category-terroirs/create';
import SubCategoryTerroirsEdit from './container/sub-category-terroirs/edit';

// SUB CATEGORY ATTRACTIVE
import SubCategoryAttractiveIcon from '@material-ui/icons/Shop';
import SubCategoryAttractiveList from './container/sub-category-attractive/list';
import SubCategoryAttractiveCreate from './container/sub-category-attractive/create';
import SubCategoryAttractiveEdit from './container/sub-category-attractive/edit';

// ATTRACTIVE
import AttractiveIcon from '@material-ui/icons/Museum';
import AttractiveList from './container/attractive/list';
import AttractiveCreate from './container/attractive/create';
import AttractiveEdit from './container/attractive/edit';
import AttractiveShow from './container/attractive/show';

// ATTRACTIVE REPORTED PROBLEM
import AttractiveReportedProblemIcon from '@material-ui/icons/ReportProblem';
import AttractiveReportedProblemList from './container/attractive-reported-problem/list';
import AttractiveReportedProblemShow from './container/attractive-reported-problem/show';

// SUB CATEGORY SERVICE
import SubCategoryServiceIcon from '@material-ui/icons/LocalLaundryService';
import SubCategoryServiceList from './container/sub-category-service/list';
import SubCategoryServiceCreate from './container/sub-category-service/create';
import SubCategoryServiceEdit from './container/sub-category-service/edit';

// SERVICE
import ServiceIcon from '@material-ui/icons/Settings';
import ServiceList from './container/service/list';
import ServiceCreate from './container/service/create';
import ServiceEdit from './container/service/edit';
import ServiceShow from './container/service/show';

// SERVICE UPDATE
import ServiceUpdateIcon from '@material-ui/icons/SystemUpdate';
import ServiceUpdateList from './container/service-update/list';
import ServiceUpdateEdit from './container/service-update/edit';
import ServiceUpdateShow from './container/service-update/show';

// SERVICE REPORTED PROBLEM
import ServiceReportedProblemIcon from '@material-ui/icons/ReportProblem';
import ServiceReportedProblemList from './container/service-reported-problem/list';
import ServiceReportedProblemShow from './container/service-reported-problem/show';

// TERMS OF USE
import TermsOfUseIcon from '@material-ui/icons/Description';
import TermsOfUseList from './container/terms-of-use/list';
import TermsOfUseCreate from './container/terms-of-use/create';

// PRIVACY POLICY
import PrivacyPolicyIcon from '@material-ui/icons/VpnKey';
import PrivacyPolicyList from './container/privacy-policy/list';
import PrivacyPolicyCreate from './container/privacy-policy/create';

// STAMP POINTS FILE
import StampPointsFileIcon from '@material-ui/icons/CardMembership';
import StampPointsFileList from './container/stamp-points-file/list';
import StampPointsFileCreate from './container/stamp-points-file/create';

// POP UP
import PopUpIcon from '@material-ui/icons/Announcement';
import PopUpList from './container/pop-up/list';
import PopUpCreate from './container/pop-up/create';
import PopUpEdit from './container/pop-up/edit';
import PopUpShow from './container/pop-up/show';

// EVENT
import EventIcon from '@material-ui/icons/DateRange';
import EventList from './container/event/list';
import EventCreate from './container/event/create';
import EventEdit from './container/event/edit';
import EventShow from './container/event/show';

// EVENT SUGGESTION
import EventSuggestionIcon from '@material-ui/icons/Assistant';
import EventSuggestionList from './container/event-suggestion/list';
import EventSuggestionShow from './container/event-suggestion/show';

// CONTEST
import ContestIcon from '@material-ui/icons/EmojiEvents';
import ContestList from './container/contest/list';
import ContestEdit from './container/contest/edit';
import ContestShow from './container/contest/show';

// CONTEST PARTICIPANT PHOTO
import ContestParticipantPhotoIcon from '@material-ui/icons/AddAPhoto';
import ContestParticipantPhotoList from './container/contest-participant-photo/list';
import ContestParticipantPhotoEdit from './container/contest-participant-photo/edit';
import ContestParticipantPhotoShow from './container/contest-participant-photo/show';

// CONTEST PARTICIPANT VIDEO
import ContestParticipantVideoIcon from '@material-ui/icons/Videocam';
import ContestParticipantVideoList from './container/contest-participant-video/list';
import ContestParticipantVideoEdit from './container/contest-participant-video/edit';
import ContestParticipantVideoShow from './container/contest-participant-video/show';

const i18nProvider = polyglotI18nProvider(locale => portugueseMessages);

const App = () => (
  <Admin
    title="Estrada Real - Admin"
    i18nProvider={i18nProvider}
    locale="pt"
    layout={Layout}
    loginPage={Login}
    dashboard={Dashboard}
    authProvider={authProvider}
    dataProvider={dataProvider}>
    {
      permissions =>
      [
        // USER ADMIN
        <Resource
          name =  "admin"
          icon = { AdminIcon }
          list = { permissions.some(e => e.key === config.roles.admin_users_admin) ? AdminList : null }
          create = { permissions.some(e => e.key === config.roles.admin_users_admin) ? AdminCreate : null }
          edit = { permissions.some(e => e.key === config.roles.admin_users_admin) ? AdminEdit : null }
          show = { permissions.some(e => e.key === config.roles.admin_users_admin) ? AdminShow : null }
        />,

        // CAMPAIGN
        <Resource
          name =  "campaign"
          icon = { CampaignIcon }
          list = { permissions.some(e => e.key === config.roles.admin_campaigns) ? CampaignList : null }
          create = { permissions.some(e => e.key === config.roles.admin_campaigns) ? CampaignCreate : null }
          edit = { permissions.some(e => e.key === config.roles.admin_campaigns) ? CampaignEdit : null }
          show = { permissions.some(e => e.key === config.roles.admin_campaigns) ? CampaignShow : null }
        />,

        // CUSTOMER
        <Resource
          name =  "customer"
          icon = { CustomerIcon }
          list = { permissions.some(e => e.key === config.roles.admin_users_customer) ? CustomerList : null }
          edit = { permissions.some(e => e.key === config.roles.admin_users_customer) ? CustomerEdit : null }
          show = { permissions.some(e => e.key === config.roles.admin_users_customer) ? CustomerShow : null }
        />,

        // SERVICE ADMIN
        <Resource
          name =  "service-admin"
          icon = { ServiceAdminIcon }
          list = { permissions.some(e => e.key === config.roles.admin_users_service_admin) ? ServiceAdminList : null }
          create = { permissions.some(e => e.key === config.roles.admin_users_service_admin) ? ServiceAdminCreate : null }
          edit = { permissions.some(e => e.key === config.roles.admin_users_service_admin) ? ServiceAdminEdit : null }
          show = { permissions.some(e => e.key === config.roles.admin_users_service_admin) ? ServiceAdminShow : null }
        />,

        // IMAGE
        <Resource
          name =  "image"
          icon = { ImageIcon }
          list = { permissions.some(e => e.key === config.roles.admin_users_customer) ? ImageList : null }
          create = { permissions.some(e => e.key === config.roles.admin_users_customer) ? ImageCreate : null }
          edit = { permissions.some(e => e.key === config.roles.admin_users_customer) ? ImageEdit : null }
        />,

        // CONTACT
        <Resource
          name =  "contact"
          icon = { ContactIcon }
          list = { permissions.some(e => e.key === config.roles.admin_contacts) ? ContactList : null }
        />,

        // ENTERPRISE CONTACT
        <Resource
          name =  "enterprise-contact"
          icon = { EnterpriseContactIcon }
          list = { permissions.some(e => e.key === config.roles.admin_enterprise_contacts) ? EnterpriseContactList : null }
        />,

        // NEWSLETTER
        <Resource
          name =  "newsletter"
          icon = { NewsletterIcon }
          list = { permissions.some(e => e.key === config.roles.admin_newsletters) ? NewsletterList : null }
        />,

        // LICENSED PRODUCT
        <Resource
          name =  "licensed-product"
          icon = { LicensedProductIcon }
          list = { permissions.some(e => e.key === config.roles.admin_licensed_products) ? LicensedProductList : null }
          create = { permissions.some(e => e.key === config.roles.admin_licensed_products) ? LicensedProductCreate : null }
          edit = { permissions.some(e => e.key === config.roles.admin_licensed_products) ? LicensedProductEdit : null }
          show = { permissions.some(e => e.key === config.roles.admin_licensed_products) ? LicensedProductShow : null }
        />,

        // TOUR PACKAGE
        <Resource
          name =  "tour-package"
          icon = { TourPackageIcon }
          list = { permissions.some(e => e.key === config.roles.admin_tour_packages) ? TourPackageList : null }
          create = { permissions.some(e => e.key === config.roles.admin_tour_packages) ? TourPackageCreate : null }
          edit = { permissions.some(e => e.key === config.roles.admin_tour_packages) ? TourPackageEdit : null }
          show = { permissions.some(e => e.key === config.roles.admin_tour_packages) ? TourPackageShow : null }
        />,

        // COPARTNER
        <Resource
          name =  "copartner"
          icon = { CopartnerIcon }
          list = { permissions.some(e => e.key === config.roles.admin_copartners) ? CopartnerList : null }
          create = { permissions.some(e => e.key === config.roles.admin_copartners) ? CopartnerCreate : null }
          edit = { permissions.some(e => e.key === config.roles.admin_copartners) ? CopartnerEdit : null }
          show = { permissions.some(e => e.key === config.roles.admin_copartners) ? CopartnerShow : null }
        />,

        // HIGHLIGHT
        <Resource
          name =  "highlight"
          icon = { HighlightIcon }
          list = { permissions.some(e => e.key === config.roles.admin_highlights) ? HighlightList : null }
          create = { permissions.some(e => e.key === config.roles.admin_highlights) ? HighlightCreate : null }
          edit = { permissions.some(e => e.key === config.roles.admin_highlights) ? HighlightEdit : null }
          show = { permissions.some(e => e.key === config.roles.admin_highlights) ? HighlightShow : null }
        />,

        // THEME
        <Resource
          name =  "theme"
          icon = { ThemeIcon }
          list = { permissions.some(e => e.key === config.roles.admin_themes) ? ThemeList : null }
          create = { permissions.some(e => e.key === config.roles.admin_themes) ? ThemeCreate : null }
          edit = { permissions.some(e => e.key === config.roles.admin_themes) ? ThemeEdit : null }
          show = { permissions.some(e => e.key === config.roles.admin_themes) ? ThemeShow : null }
        />,

        // THEME CURIOSITY
        <Resource
          name =  "theme-curiosity"
          icon = { ThemeCuriosityIcon }
          list = { permissions.some(e => e.key === config.roles.admin_themes) ? ThemeCuriosityList : null }
          create = { permissions.some(e => e.key === config.roles.admin_themes) ? ThemeCuriosityCreate : null }
          edit = { permissions.some(e => e.key === config.roles.admin_themes) ? ThemeCuriosityEdit : null }
          show = { permissions.some(e => e.key === config.roles.admin_themes) ? ThemeCuriosityShow : null }
        />,

        // SUB THEME
        <Resource
          name =  "sub-theme"
          icon = { SubThemeIcon }
          list = { permissions.some(e => e.key === config.roles.admin_themes) ? SubThemeList : null }
          create = { permissions.some(e => e.key === config.roles.admin_themes) ? SubThemeCreate : null }
          edit = { permissions.some(e => e.key === config.roles.admin_themes) ? SubThemeEdit : null }
          show = { permissions.some(e => e.key === config.roles.admin_themes) ? SubThemeShow : null }
        />,

        // CITY
        <Resource
          name =  "city"
          icon = { CityIcon }
          list = { permissions.some(e => e.key === config.roles.admin_cities) ? CityList : null }
          create = { permissions.some(e => e.key === config.roles.admin_cities) ? CityCreate : null }
          edit = { permissions.some(e => e.key === config.roles.admin_cities) ? CityEdit : null }
          show = { permissions.some(e => e.key === config.roles.admin_cities) ? CityShow : null }
        />,

        // WAY
        <Resource
          name =  "way"
          icon = { WayIcon }
          list = { permissions.some(e => e.key === config.roles.admin_ways) ? WayList : null }
          edit = { permissions.some(e => e.key === config.roles.admin_ways) ? WayEdit : null }
          show = { permissions.some(e => e.key === config.roles.admin_ways) ? WayShow : null }
        />,

        // WAY FILES
        <Resource
          name =  "way-files"
          icon = { WayFilesIcon }
          list = { permissions.some(e => e.key === config.roles.admin_ways) ? WayFilesList : null }
          create = { permissions.some(e => e.key === config.roles.admin_ways) ? WayFilesCreate : null }
        />,

        // WAY
        <Resource
          name =  "way-route"
          icon = { WayRouteIcon }
          list = { permissions.some(e => e.key === config.roles.admin_way_routes) ? WayRouteList : null }
          create = { permissions.some(e => e.key === config.roles.admin_way_routes) ? WayRouteCreate : null }
          edit = { permissions.some(e => e.key === config.roles.admin_way_routes) ? WayRouteEdit : null }
          show = { permissions.some(e => e.key === config.roles.admin_way_routes) ? WayRouteShow : null }
        />,

        // WAY FILES
        <Resource
          name =  "way-route-files"
          icon = { WayRouteFilesIcon }
          list = { permissions.some(e => e.key === config.roles.admin_way_routes) ? WayRouteFilesList : null }
          create = { permissions.some(e => e.key === config.roles.admin_way_routes) ? WayRouteFilesCreate : null }
        />,

        // CHECK POINT
        <Resource
          name =  "check-point"
          icon = { CheckPointIcon }
          list = { permissions.some(e => e.key === config.roles.admin_check_points) ? CheckPointList : null }
          create = { permissions.some(e => e.key === config.roles.admin_check_points) ? CheckPointCreate : null }
          edit = { permissions.some(e => e.key === config.roles.admin_check_points) ? CheckPointEdit : null }
          show = { permissions.some(e => e.key === config.roles.admin_check_points) ? CheckPointShow : null }
        />,

        // CHECK POINT REPORTED PROBLEM
        <Resource
          name =  "check-point-reported-problem"
          icon = { CheckPointReportedProblemIcon }
          list = { permissions.some(e => e.key === config.roles.admin_reported_problems) ? CheckPointReportedProblemList : null }
          show = { permissions.some(e => e.key === config.roles.admin_reported_problems) ? CheckPointReportedProblemShow : null }
        />,

        // CATEGORY TERROIRS
        <Resource
          name =  "category-terroirs"
          icon = { CategoryTerroirsIcon }
          list = { permissions.some(e => e.key === config.roles.admin_categories_terroirs) ? CategoryTerroirsList : null }
          create = { permissions.some(e => e.key === config.roles.admin_categories_terroirs) ? CategoryTerroirsCreate : null }
          edit = { permissions.some(e => e.key === config.roles.admin_categories_terroirs) ? CategoryTerroirsEdit : null }
        />,

        // SUB CATEGORY TERROIRS
        <Resource
          name =  "sub-category-terroirs"
          icon = { SubCategoryTerroirsIcon }
          list = { permissions.some(e => e.key === config.roles.admin_categories_terroirs) ? SubCategoryTerroirsList : null }
          create = { permissions.some(e => e.key === config.roles.admin_categories_terroirs) ? SubCategoryTerroirsCreate : null }
          edit = { permissions.some(e => e.key === config.roles.admin_categories_terroirs) ? SubCategoryTerroirsEdit : null }
        />,

        // SUB CATEGORY ATTRACTIVE
        <Resource
          name =  "sub-category-attractive"
          icon = { SubCategoryAttractiveIcon }
          list = { permissions.some(e => e.key === config.roles.admin_attractives) ? SubCategoryAttractiveList : null }
          create = { permissions.some(e => e.key === config.roles.admin_attractives) ? SubCategoryAttractiveCreate : null }
          edit = { permissions.some(e => e.key === config.roles.admin_attractives) ? SubCategoryAttractiveEdit : null }
        />,

        // ATTRACTIVE
        <Resource
          name =  "attractive"
          icon = { AttractiveIcon }
          list = { permissions.some(e => e.key === config.roles.admin_attractives) ? AttractiveList : null }
          create = { permissions.some(e => e.key === config.roles.admin_attractives) ? AttractiveCreate : null }
          edit = { permissions.some(e => e.key === config.roles.admin_attractives) ? AttractiveEdit : null }
          show = { permissions.some(e => e.key === config.roles.admin_attractives) ? AttractiveShow : null }
        />,

        // ATTRACTIVE REPORTED PROBLEM
        <Resource
          name =  "attractive-reported-problem"
          icon = { AttractiveReportedProblemIcon }
          list = { permissions.some(e => e.key === config.roles.admin_reported_problems) ? AttractiveReportedProblemList : null }
          show = { permissions.some(e => e.key === config.roles.admin_reported_problems) ? AttractiveReportedProblemShow : null }
        />,

        // SUB CATEGORY SERVICE
        <Resource
          name =  "sub-category-service"
          icon = { SubCategoryServiceIcon }
          list = { permissions.some(e => e.key === config.roles.admin_services) ? SubCategoryServiceList : null }
          create = { permissions.some(e => e.key === config.roles.admin_services) ? SubCategoryServiceCreate : null }
          edit = { permissions.some(e => e.key === config.roles.admin_services) ? SubCategoryServiceEdit : null }
        />,

        // SERVICE
        <Resource
          name =  "service"
          icon = { ServiceIcon }
          list = { permissions.some(e => e.key === config.roles.admin_services) ? ServiceList : null }
          create = { permissions.some(e => e.key === config.roles.admin_services) ? ServiceCreate : null }
          edit = { permissions.some(e => e.key === config.roles.admin_services) ? ServiceEdit : null }
          show = { permissions.some(e => e.key === config.roles.admin_services) ? ServiceShow : null }
        />,

        // SERVICE UPDATE
        <Resource
          name =  "service-update"
          icon = { ServiceUpdateIcon }
          list = { permissions.some(e => e.key === config.roles.admin_updates_service) ? ServiceUpdateList : null }
          edit = { permissions.some(e => e.key === config.roles.admin_updates_service) ? ServiceUpdateEdit : null }
          show = { permissions.some(e => e.key === config.roles.admin_updates_service) ? ServiceUpdateShow : null }
        />,

        // SERVICE REPORTED PROBLEM
        <Resource
          name =  "service-reported-problem"
          icon = { ServiceReportedProblemIcon }
          list = { permissions.some(e => e.key === config.roles.admin_reported_problems) ? ServiceReportedProblemList : null }
          show = { permissions.some(e => e.key === config.roles.admin_reported_problems) ? ServiceReportedProblemShow : null }
        />,

        // TERMS OF USE
        <Resource
          name =  "terms-of-use"
          icon = { TermsOfUseIcon }
          list = { permissions.some(e => e.key === config.roles.admin_terms_of_use) ? TermsOfUseList : null }
          create = { permissions.some(e => e.key === config.roles.admin_terms_of_use) ? TermsOfUseCreate : null }
        />,

        // PRIVACY POLICY
        <Resource
          name =  "privacy-policy"
          icon = { PrivacyPolicyIcon }
          list = { permissions.some(e => e.key === config.roles.admin_privacy_policys) ? PrivacyPolicyList : null }
          create = { permissions.some(e => e.key === config.roles.admin_privacy_policys) ? PrivacyPolicyCreate : null }
        />,

        // STAMP POINTS FILE
        <Resource
          name =  "stamp-points-file"
          icon = { StampPointsFileIcon }
          list = { permissions.some(e => e.key === config.roles.admin_stamp_points_files) ? StampPointsFileList : null }
          create = { permissions.some(e => e.key === config.roles.admin_stamp_points_files) ? StampPointsFileCreate : null }
        />,

        // POP UP
        <Resource
          name =  "pop-up"
          icon = { PopUpIcon }
          list = { permissions.some(e => e.key === config.roles.admin_pop_ups) ? PopUpList : null }
          create = { permissions.some(e => e.key === config.roles.admin_pop_ups) ? PopUpCreate : null }
          edit = { permissions.some(e => e.key === config.roles.admin_pop_ups) ? PopUpEdit : null }
          show = { permissions.some(e => e.key === config.roles.admin_pop_ups) ? PopUpShow : null }
        />,

        // EVENT
        <Resource
          name =  "event"
          icon = { EventIcon }
          list = { permissions.some(e => e.key === config.roles.admin_events) ? EventList : null }
          create = { permissions.some(e => e.key === config.roles.admin_events) ? EventCreate : null }
          edit = { permissions.some(e => e.key === config.roles.admin_events) ? EventEdit : null }
          show = { permissions.some(e => e.key === config.roles.admin_events) ? EventShow : null }
        />,

        // EVENT SUGGESTION
        <Resource
          name =  "event-suggestion"
          icon = { EventSuggestionIcon }
          list = { permissions.some(e => e.key === config.roles.admin_events_suggestion) ? EventSuggestionList : null }
          show = { permissions.some(e => e.key === config.roles.admin_events_suggestion) ? EventSuggestionShow : null }
        />,

        // CONTEST
        <Resource
          name =  "contest"
          icon = { ContestIcon }
          list = { permissions.some(e => e.key === config.roles.admin_contests) ? ContestList : null }
          edit = { permissions.some(e => e.key === config.roles.admin_contests) ? ContestEdit : null }
          show = { permissions.some(e => e.key === config.roles.admin_contests) ? ContestShow : null }
        />,

        // CONTEST PARTICIPANT PHOTO
        <Resource
          name =  "contest-participant-photo"
          icon = { ContestParticipantPhotoIcon }
          list = { permissions.some(e => e.key === config.roles.admin_contests) ? ContestParticipantPhotoList : null }
          edit = { permissions.some(e => e.key === config.roles.admin_contests) ? ContestParticipantPhotoEdit : null }
          show = { permissions.some(e => e.key === config.roles.admin_contests) ? ContestParticipantPhotoShow : null }
        />,

        // CONTEST PARTICIPANT VIDEO
        <Resource
          name =  "contest-participant-video"
          icon = { ContestParticipantVideoIcon }
          list = { permissions.some(e => e.key === config.roles.admin_contests) ? ContestParticipantVideoList : null }
          edit = { permissions.some(e => e.key === config.roles.admin_contests) ? ContestParticipantVideoEdit : null }
          show = { permissions.some(e => e.key === config.roles.admin_contests) ? ContestParticipantVideoShow : null }
        />,

        // REFERENCES
        <Resource name = "category-service" />,
        <Resource name = "category-attractive" />,
        <Resource name = "setup/roles" />,
        <Resource name = "setup/states" />,
        <Resource name = "setup/cities" />,
        <Resource name = "setup/types-file" />,
        <Resource name = "setup/cities/with/detail" />,
        <Resource name = "setup/cities/not/detail" />,
        <Resource name = "setup/campaigns" />,
        <Resource name = "service/not/admin" />,
        <Resource name = "setup/contests" />
      ]
    }
  </Admin>
);

export default App;
