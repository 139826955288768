import React from 'react';
import {
    Show,
    TextField,
    BooleanField,
    SimpleShowLayout
} from 'react-admin';

// own ui
import CustomImageField from '../../ui/custom-image-field';
import CitiesField from '../../ui/cities-field';

const AttractiveShow = props => (
    <Show
      title="Ponto de Verificação" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <CustomImageField source="images" label="Imagem" showAll={true} sortable={false}/>
            <TextField source="sub_category_attractive_name" label="Sub Categoria" sortable={false} />
            <TextField source="sub_theme_name" label="Sub Tema" sortable={false} />
            <TextField source="name.pt_br" label="Nome PT-BR" sortable={false} />
            <TextField source="name.en_us" label="Nome EN-US" sortable={false} />
            <TextField source="description.pt_br" label="Descrição PT-BR" sortable={false} />
            <TextField source="description.en_us" label="Descrição EN-US" sortable={false} />
            <TextField source="address.pt_br" label="Endereço PT-BR" sortable={false} />
            <TextField source="address.en_us" label="Endereço EN-US" sortable={false} />
            <TextField source="lat" label="Latitude" sortable={false} />
            <TextField source="lng" label="Longitude" sortable={false} />
            <TextField source="sub_category_terroirs_name" label="Sub Categoria Terroirs" sortable={false} />
            <CitiesField label="Cidades" sortable={false}/>
            <BooleanField source="active" label="Ativo" sortable={false} />
        </SimpleShowLayout>
    </Show>
);

export default AttractiveShow;
