import React from 'react';
import {
  required,
  minLength,
  Edit,
  TextInput,
  SimpleForm,
  BooleanInput
} from 'react-admin';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';

const validateName = [required('Digite o nome!')];
const validateDocument = [required('Digite o CPF!')];
const validatePhone = [required('Digite o Telefone!'), minLength(15, 'Telefone (DD) DDDDD-DDDD')];

const ServiceAdminEdit = (props) => (
  <Edit {...props} title={ <EditTitle subtitle="Editar Admin de Serviço: " value="email" /> }>
    <SimpleForm redirect="/service-admin" toolbar={<CustomEditToolbar />} >
      <TextInput source="id" label="Id" disabled />
      <TextInput source="service_name" label="Serviço" fullWidth={true} disabled />
      <TextInput source="email" label="E-mail" fullWidth={true} disabled />
      <TextInput source="profile.name" label="Nome" fullWidth={true} validate={validateName} />
      <TextInput source="profile.document" label="Documento" fullWidth={true} validate={validateDocument} />
      <TextInput source="password" fullWidth={true} label="Senha" />
      <TextInput source="profile.phone" label="Telefone (DD) DDDDD-DDDD" fullWidth={true} validate={validatePhone} />
      <TextInput source="profile.responsibility" label="Cargo" fullWidth={true} />
      <BooleanInput source="active" label="Ativo" />
    </SimpleForm>
  </Edit>
);

export default ServiceAdminEdit;
