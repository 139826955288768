import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = eventsSuggestion => {
    const data = eventsSuggestion.map(eventSuggestion => ({
        id: eventSuggestion.id,
        nome: eventSuggestion.contact_name,
        email: eventSuggestion.contact_email,
        telefone: eventSuggestion.contact_phone,
        evento: eventSuggestion.name,
        descricao: eventSuggestion.description,
        e_gratis: (eventSuggestion.is_free === true ? 'Sim' : 'Não'),
        cidade: eventSuggestion.address.city_name,
        rua: eventSuggestion.address.street,
        numero: eventSuggestion.address.number,
        complemento: eventSuggestion.address.complement,
        cep: eventSuggestion.address.zip_code,
        bairro: eventSuggestion.address.neighborhood,
        imagem: eventSuggestion.cover_image_url,
        dias: eventSuggestion.days_string,
        links: eventSuggestion.links_string,
        criado_em: eventSuggestion.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'nome', 'email', 'telefone', 'evento', 'descricao',
                 'e_gratis', 'cidade', 'rua', 'numero', 'complemento', 'cep',
                 'bairro', 'imagem', 'dias', 'links', 'criado_em']
    });
    downloadCSV(csv, 'eventsSuggestion');
}

export default exporter;
