import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    DeleteButton
} from 'react-admin';

// own ui
import CustomFilterWay from '../../ui/custom-filter-way';
import LinkField from '../../ui/link-field';

const WayFilesList = (props) => (
    <List
      title="Arquivos do Caminho" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterWay /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ false } >
        <Datagrid>
          <TextField source="type_file_name" label="Tipo" sortable={true} />
          <LinkField source="url" text="Ver" label="Arquivo" sortable={false}/>
          <TextField source="type" label="Extensão" sortable={true} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
          <DeleteButton />
        </Datagrid>
    </List>
);

export default WayFilesList;
