import React from 'react';
import {
  required,
  Create,
  SimpleForm,
  FileInput,
  FileField,
  ReferenceInput,
  SelectInput
} from 'react-admin';

const validateWay = required('Selecione um caminho!');
const validateTypeFile = required('Selecione o tipo de arquivo!');
const validateFile = required('Selecione um arquivo!');

const WayFilesCreate = (props) => (
  <Create title="Novo Arquivo do Caminho" {...props}>
    <SimpleForm redirect="/way-files" >
      <ReferenceInput label="Caminho" source="way_id" fullWidth={true} reference="way" validate={validateWay}>
        <SelectInput optionText="name.pt_br" optionValue="id" />
      </ReferenceInput>
      <ReferenceInput label="Tipo de Arquivo" source="type_file" fullWidth={true} reference="setup/types-file" filter={{ type_model: 'way' }} validate={validateTypeFile}>
        <SelectInput optionText="name" optionValue="type" />
      </ReferenceInput>
      <FileInput source="file" label="Tamanho Máximo do Arquivo: 5MB" maxSize={5000000} validate={validateFile}>
        <FileField src="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);

export default WayFilesCreate;
