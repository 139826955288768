import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    BooleanField
} from 'react-admin';

// own ui
import CustomFilterTextCityStampPointActive from '../../ui/custom-filter-text-city-stamp-point-active';
import CustomImageField from '../../ui/custom-image-field';
import CheckPointExporter from '../../exporter/check-point-exporter';

const CheckPointList = (props) => (
    <List
      title="Pontos de Check-In" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterTextCityStampPointActive /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ CheckPointExporter } >
        <Datagrid
          rowClick="show">
          <TextField source="id" sortable={true} />
          <CustomImageField source="images" label="Imagem" sortable={false}/>
          <TextField source="way_name" label="Caminho" sortable={false} />
          <TextField source="city.name" label="Cidade" sortable={false} />
          <TextField source="identifier" label="Identificador" sortable={false} />
          <TextField source="name.pt_br" label="Nome" sortable={false} />
          <TextField source="title.pt_br" label="Título" sortable={false} />
          <BooleanField source="stamp_point" label="Ponto de Carimbo" sortable={false} />
          <BooleanField source="active" label="Ativo" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
          <EditButton />
        </Datagrid>
    </List>
);

export default CheckPointList;
