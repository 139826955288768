import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = enterpriseContacts => {
    const data = enterpriseContacts.map(enterpriseContact => ({
        id: enterpriseContact.id,
        tipo: enterpriseContact.type_enterprise_contact.name,
        empresa: enterpriseContact.enterprise_name,
        email: enterpriseContact.email_owner,
        telefone: enterpriseContact.phone_owner,
        cnpj: enterpriseContact.enterprise_register_number,
        site: enterpriseContact.site,
        cidade: enterpriseContact.city.name,
        mensagem: enterpriseContact.description,
        criado_em: enterpriseContact.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'tipo', 'empresa', 'email', 'telefone', 'cnpj', 'site',
                 'cidade', 'mensagem', 'criado_em']
    });
    downloadCSV(csv, 'enterprise-contacts');
}

export default exporter;
