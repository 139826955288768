import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = themes => {
    const data = themes.map(theme => ({
        id: theme.id,
        nome_pt_br: theme.name.pt_br,
        nome_en_us: theme.name.en_us,
        titulo_pt_br: theme.title.pt_br,
        titulo_en_us: theme.title.en_us,
        descricao_pt_br: theme.description.pt_br,
        descricao_en_us: theme.description.en_us,
        criado_em: theme.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'nome_pt_br', 'nome_en_us', 'titulo_pt_br', 'titulo_en_us',
                 'descricao_pt_br', 'descricao_en_us', 'criado_em']
    });
    downloadCSV(csv, 'themes');
}

export default exporter;
