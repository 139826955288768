import React from 'react';

const EditTitle = (props) => {
  return <span>
          { props.subtitle }
          {
            props.subvalue && props.record && props.record[props.value][props.subvalue] ? `${props.record[props.value][props.subvalue]}` :
            props.record && props.record[props.value] ? `${props.record[props.value]}` : `${props.value}`
          }
        </span>
}

export default EditTitle;
