import React from 'react';
import {
  required,
  Edit,
  TextInput,
  SimpleForm,
  ImageField,
  ImageInput
} from 'react-admin';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';
import CustomImageField from '../../ui/custom-image-field';

const validateName = [required('Digite o nome!')];
const validateLabel = [required('Digite a legenda!')];

const ImageEdit = (props) => (
  <Edit {...props} title={ <EditTitle subtitle="Editar Imagem: " value="name" /> }>
    <SimpleForm redirect="/image" toolbar={<CustomEditToolbar />} >
      <TextInput source="id" label="Id" disabled />
      <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
      <TextInput source="label" label="Legenda" fullWidth={true} validate={validateLabel} />
      <CustomImageField source="url" label="Imagem" showAll={true} sortable={false}/>
      <br/>
      <ImageInput source="image" label="Imagem - Tamanho Máximo: 5MB" maxSize={5000000} accept="image/png,image/gif,image/jpeg,image/jpg" >
        <ImageField src="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export default ImageEdit;
