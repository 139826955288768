import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = subCategoriesService => {
    const data = subCategoriesService.map(subCategoryService => ({
        id: subCategoryService.id,
        categoria_servico: subCategoryService.category_service_name,
        nome_pt_br: subCategoryService.name.pt_br,
        nome_en_us: subCategoryService.name.en_us,
        criado_em: subCategoryService.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'categoria_servico', 'nome_pt_br', 'nome_en_us', 'criado_em']
    });
    downloadCSV(csv, 'subCategoriesService');
}

export default exporter;
