import * as React from 'react';
import { Layout, Sidebar } from 'react-admin';
import AppBar from './appbar';
import Menu from './menu';
import { lightTheme } from './theme';

const CustomSidebar = (props: any) => <Sidebar {...props} size={200} />;
const LayoutCreate = (props: any) => (
  <Layout
      {...props}
      appBar={AppBar}
      sidebar={CustomSidebar}
      menu={Menu}
      theme={lightTheme}
  />
);

export default LayoutCreate;
