import React, { Fragment } from 'react';
import { change } from 'redux-form';
import {
  required,
  Create,
  SimpleForm,
  TranslatableInputs,
  TextInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  FormDataConsumer
} from 'react-admin';

// own ui
import RichTextInput from '../../ui/rich-text-input';

const validateTheme = required('Selecione um tema!');
const validateTitle = [required('Digite o título!')];
const validateDescription = [required('Digite a descrição!')];
const validateImage = required('Selecione uma imagem!');
const validateState = required('Selecione um estado!');
const validateCity = required('Selecione uma cidade!');

const ThemeCuriosityCreate = (props) => (
  <Create title="Nova Curiosidade" {...props}>
    <SimpleForm redirect="/theme-curiosity" >
      <ReferenceInput label="Tema" source="theme_id" fullWidth={true} reference="theme" validate={validateTheme}>
        <SelectInput optionText="name.pt_br" optionValue="id" />
      </ReferenceInput>
      <TranslatableInputs locales={['pt_br', 'en_us']} defaultLocale="pt_br">
        <TextInput source="title" label="Título" fullWidth={true} validate={validateTitle} />
        <RichTextInput source="description" label="Descrição" validate={validateDescription} />
      </TranslatableInputs>
      <FormDataConsumer>
      {
        ({ formData, className, ...rest}) =>
        {
          return (
            <Fragment>
              <ReferenceInput label="Estado" source="state_id" fullWidth={true} reference="setup/states" validate={validateState} filterToQuery={ searchText => ({ q: searchText })}>
                <AutocompleteInput optionText="name" optionValue="id" onChange={() => rest.dispatch(change("", "state_id", ""))} limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }}/>
              </ReferenceInput>
              <ReferenceInput filter={{state_id: formData.state_id}} fullWidth={true} label="Cidade" source="city_id" reference="setup/cities" validate={validateCity}>
                <AutocompleteInput optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
              </ReferenceInput>
            </Fragment>
          )
        }
      }
      </FormDataConsumer>
      <ReferenceInput label="Imagem" source="image_id" fullWidth={true} reference="image" validate={validateImage}>
        <AutocompleteInput optionText="name" optionValue="id" shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default ThemeCuriosityCreate;
